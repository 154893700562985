
import md5 from "md5";
import React from "react";

// reactstrap misc
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Col,
    Row
} from "reactstrap";

import blacklogo from "assets/img/logo.svg";
import RequestEngine from "../../core/RequestEngine";
import CoreEngine from "../../core/CoreEngine";
import { HollowDotsSpinner } from 'react-epic-spinners'
import {Utilites} from "../../core/Utilites";
import Constants from "../../core/Constants";

class Reset extends CoreEngine {

    constructor(props) {
        super(props);

        this.state = {
            password:"",
            password2:"",
            token:"",
            loading : false,
        };
        this.engine = new RequestEngine();
    }

    componentDidMount() {

        const token = this.props.match.params.id
        if (token){
            this.setState({
                token
            });
        }
    }



    async resetpass(){
        let {password,password2,token} = this.state
        try {

            if(password!=password2){
                Utilites.showErrorMessage("Please Confirm Password");
                return;
            }



            if(!Utilites.validatePassword(password)){
                Utilites.showErrorMessage("Please Write a Strong Password");
                return;
            }
            password= md5(password)

            this.setState({loading : true})
            const result = await this.engine.resetpassword(token,password)
            if(result.status === 200 && result.data.success){
                Utilites.showSucessMessage("Password Changed!")
                this.props.history.push('/auth/login');
            }else{
                Utilites.showErrorMessage(result.data.message)
                this.setState({password:""})
            }
        }catch (e) {
            alert("Wrong login info  ")
            this.setState({password:"",loading:false})
        }

        this.setState({loading : false})
    }


    render() {
        const {password,password2,loading} = this.state
        return (
            <div className="login-page">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" lg="4" md="6">
                            <Form action="" className="form" method="">
                                <Card className="card-login">
                                    <CardHeader>
                                        <CardHeader>
                                            <div className="logo-img">
                                                <img src={blacklogo} alt="react-logo" className="innerLogo" />
                                            </div>

                                        </CardHeader>
                                    </CardHeader>

                                        <CardBody>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="nc-icon nc-key-25" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Password"
                                                    type="password"
                                                    autoComplete="off"
                                                    value={password}
                                                    onKeyPressCapture={event => {
                                                        if(event.charCode == 13){
                                                            this.resetpass()
                                                        }
                                                    }}
                                                    onChange={e =>
                                                        this.changeInput(e, "password")
                                                    }
                                                />
                                            </InputGroup>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="nc-icon nc-key-25" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Confirm password"
                                                    type="password"
                                                    autoComplete="off"
                                                    value={password2}
                                                    onKeyPressCapture={event => {
                                                        if(event.charCode == 13){
                                                            this.resetpass()
                                                        }
                                                    }}
                                                    onChange={e =>
                                                        this.changeInput(e, "password2")
                                                    }
                                                />
                                            </InputGroup>
                                            <br />

                                        </CardBody>
                                        <CardFooter>
                                            {loading && <div className="logo-img">
                                                <HollowDotsSpinner color="#51BCDA"  animationDelay={50} />
                                            </div>}
                                            {!loading && <Button
                                                block

                                                className="btn-round mb-3"
                                                style={{background:"#409DBA"}}
                                                onClick={() => this.resetpass()}
                                            >
                                                Login
                                            </Button>}

                                            <div>
                                                <a  onClick={() => {

                                                    this.setState({
                                                        forgotpassword:true
                                                    })
                                                }}>Forgot password</a>
                                            </div>

                                        </CardFooter>

                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </Container>
                <div
                    className="full-page-background"
                    style={{
                        backgroundImage: `url(${require("assets/img/bg/david-marcu.jpg")})`
                        //  backgroundImage: `url(${require("assets/img/bg/why-was-bitcoin-invented-1024x640.jpg")})`

                    }}
                />
            </div>
        );
    }
}

export default Reset;
