import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col,
    DropdownItem,
    UncontrolledDropdown,
    PaginationItem, PaginationLink, Pagination,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import ReactTable from "react-table";

class ItemList extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            page:0,
            count:0,
            pageSize:10,
            innerloading:true,
        };
        this.pathname = "item";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.checkPermission();
    }


    checkPermission(){
        const managertype = localStorage.getItem("managertype");
        if(!managertype || managertype=="normaluser"){
            this.props.history.push('/admin/notfound');
        }
    }

    async handleDelete(valueid) {
        this.props.loadingAction(true)
        const result = await this.engine.deleteItem(this.pathname,valueid)
        if(result){
            this.handlePage();
        }
        this.props.loadingAction(false)

    }


    handleEdit(index,dataid) {
        const data = this.state.dataSource[index].data

        this.props.history.push({
            pathname:'/admin/'+this.pathname+'/edit/'+dataid,
            data
        });

    }

    handleActions(index,item) {

        return (<UncontrolledDropdown>
            <DropdownToggle
                aria-expanded={false}
                aria-haspopup={true}
                caret
                className="btn-round btn-block"
                color="primary"
                data-toggle="dropdown"
                id="dropdownMenuButton"
                type="button"
            >
                Actions
            </DropdownToggle>
            <DropdownMenu>

                <DropdownItem  eventKey="2" onClick={() => this.handleEdit(index,item._id)}>View</DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>)
    }






    async callPage(page,pageSize=10,filtered = [],sorted=[]) {

        this.setState({
            innerloading : true,
        })
        const response = await  this.engine.postItem(this.pathname,"/list/"+page+"/"+pageSize,filtered,sorted);


        if(response && response.status === 200){
            this.setState({
                innerloading:false,
                count:response.data.data.count,
                page:page,
                pageSize:pageSize,
                    dataSource: response.data.data.data.map((item, key) => {
                        return {
                            id: key,
                            data:item,
                            pallet:item.pallet,
                            serial: item.serial ,
                            model: item.model ,
                            itemgroup:item.itemgroup && item.itemgroup.title,
                            createdAt:this.renderDate(item.createdAt),
                            actions: (
                                // we've added some custom button actions
                                this.handleActions(key,item)
                            )
                        };
                    })
                }
            );
        }
        this.props.loadingAction(false);
    }

    handlePage() {
        this.props.loadingAction(true);
        this.callPage()
    }


    handleAdd() {
        this.props.history.push('/admin/'+this.pathname+'/add');
    }


    render() {
        const {dataSource,count,innerloading,pageSize} = this.state;

        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= count; i++) {
            pageNumbers.push(i);
        }
        const renderPageNumbers = pageNumbers.map((number, index) => {
            return (
                <PaginationItem   key={index}>
                    <PaginationLink
                        onClick={()=>this.callPage(number,pageSize)}
                    >
                        {number}
                    </PaginationLink>
                </PaginationItem>
            );
        })


        let columns = [
            {
                Header: "Auction",
                accessor: "itemgroup"
            },
            {
                Header: "pallet",
                accessor: "pallet"
            },
            {
                Header: "serial",
                accessor: "serial"
            },

            {
                Header: "Created",
                accessor: "createdAt",

            },
            {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false
            }
        ]


        return (
            <>

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" className="pull-left">List</CardTitle>

                                </CardHeader>
                                <CardBody>
                                    <Pagination
                                        className="pagination pagination-primary"
                                        listClassName="pagination-primary"
                                        style={{    overflow: "scroll"}}
                                    >
                                        {renderPageNumbers}
                                    </Pagination>
                                    <ReactTable
                                        data={dataSource}
                                        defaultFilterMethod={this.filterCaseInsensitive}
                                        filterable
                                        manual
                                        loading={innerloading}
                                        columns={columns}
                                        defaultPageSize={10}
                                        showPaginationTop
                                        onFetchData={(state, instance) => {
                                            this.setState({
                                                innerloading:true
                                            })
                                            this.callPage(state.page, state.pageSize,state.filtered,state.sorted)
                                        }}
                                        showPaginationBottom={false}
                                        className="-striped -highlight primary-pagination"
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(ItemList);
