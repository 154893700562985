const Constants = {
  serverlink:'https://auctionapi.regencytechnologies.com/',
    // ajax link
   // serverlink : 'http://46.101.78.123:3061/',
   // sitelink: 'http://46.101.78.123:3065/',
sitelink: 'https://auction.regencytechnologies.com/', // http://46.101.78.123:4321/db/db_auction
//serverlink: 'http://localhost:3061/',
//sitelink: 'http://localhost:3000/',
    timeout: 400000000000
};

export default Constants;
