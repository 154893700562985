import React from 'react';
import Constants from "../../core/Constants";

export default function viewUserTemplate(options) {
    const key = options.column.dataField
    const pallet = options.data.pallet
    const isTopCell = options.data.category
    const bidaction = options.data[key];
    const palletinginprogress = bidaction?.palletinginprogress;
    const palletcompleted = bidaction?.palletcompleted;

    if(pallet.toString().length<2){
        return <span className="name"></span>;
    }

    let style = {};
    if(palletcompleted){
        style = {color:"green"}
    }
    if(palletinginprogress){
        style = {color:"red"}
    }
    if(isTopCell){
        return <span className="name" >$ {bidaction.value ? bidaction.value:bidaction}</span>;
    }



    if (!bidaction) {
        return <span className="name" style={style}>$ 0.00</span>;
    }

        return <span className="name" style={style}>$ {bidaction.value ? bidaction.value:bidaction}</span>;

}
