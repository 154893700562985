import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";
import * as XLSX from 'xlsx';
import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';

import Constants from "../../core/Constants";
import {Utilites} from "../../core/Utilites";
import {Button} from "bootstrap/js/src";
import {ProgressBar} from "devextreme-react/progress-bar";

function statusFormat(value) {
    return `Loading: ${parseFloat(value * 100).toFixed(0)}%`;
}

const SKIPPEDCOLS=["price","product","location","customer","description","total","","bid"];
const mapedInput = ["warehouse","stockcode","location","pallet","palletdescription","palletdept","serial","make","model","mfgrserial","processortype","processorspeed","ram","harddrive","coa","screensize","numbrofcors","opticaldrive","laptopcore","wireless","ethernet","formfactor","hddcaddy","adaptor","battery","grade","category","note","quantity","assettag",
    "operatingsystem",
    "nbrofhdmi",
    "mminput",
    "avcable",
    "accincluded",
    "accessories",
    "accessoriesincluded",
    "adapter",
    "activationstatus",
    "adaptervoltage",
    "avcables",
    "bandcolor",
    "bandtype",
    "bluetooth",
    "cables",
    "cameras",
    "capacity",
    "caraudio",
    "carrier",
    "casecolor",
    "manserial",
    "cechnbr",
    "channels",
    "charger",
    "color",
    "computernetworkingaccessorie",
    "connection",
    "controllers",
    "cpugeneration",
    "description",
    "digitaloutputs",
    "dimensions",
    "dvdbluraymovies",
    "externalstorage",
    "firmware",
    "firmwareversion",
    "games",
    "gps",
    "graphicschipset",
    "harddrivequantity",
    "harddrivesize",
    "hddserial",
    "hdmiinput",
    "hdmioutput",
    "homeaudio",
    "homevideo",
    "inkjetprinters",
    "input",
    "inputs",
    "internalstorage",
    "keyboard",
    "lenstype",
    "manmodelnbr",
    "mapsversion",
    "megapixels",
    "memory",
    "mfgdate",
    "modeltype",
    "mount",
    "mountingbracket",
    "musical",
    "network",
    "nbrofcores",
    "opticalinput",
    "opticalzoom",
    "output",
    "outputs",
    "powercord",
    "prepaid",
    "radiotuner",
    "rcainput",
    "registered",
    "remote",
    "screenresolution",
    "serversize",
    "shuttercount",
    "simcard",
    "smallappliances",
    "softwareversion",
    "speakertype",
    "speed",
    "stand",
    "storagesize",
    "subwoofer",
    "totalqty",
    "touchscreen",
    "toyspromotionalitems",
    "type",
    "usbcable",
    "usbinput",
    "vacuums",
    "warranty",
    "webcam",
    "widescreen",
    "year",
    "category",
    "weight",
    "count",
    "destroy",
    "sysproid",
    "condition",
    "failedtech",
    "qtool",
    "processor",
    "businessclass",
    "laptopcategory",
    "numberofcameralenses",
    "inventorycategory",
    "grade",
    "monitorgrade",
    "treed",
    "parentserial",
    "cd",
    "testdonotuse",
    "fmvprice",
    "fmv",
    "screencondition",
    "laptopyear",
    "desktopcategory",
    "generation",
    "ramtype",
    "boxedtvs",
    "brokenscreen",
    "iosversion",
    "vram",
    "imei",
    "capacitygb",
    "gputype",
    "chargingport",
    "prototype",
    "quantity",
    "supplierid",
    "networkmodule",
    "sfpmodel",
    "sfpquantity",
    "upc",
    "graphics",
    "bioslocked",
    "osinstalled",
    "chiprank",
    "lamphours","numberofcores","cpusspec","harddrivecaddy","memorytype"];
export default class UploadManager extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            from:"",
            title:"",
            progress:5,
            to:"",
            _id:"",
            sheetlist:[],
            admincol:[],
            admincollist:[],
            bidcategorylist:[],
            readedData:null,
            sheet:"",
            fullpicture:"",
            picture:"",
            loading : false,

            location:"",
            palletdepts:"",
            palletdeptslist:[],
            category:"",
            step:1,
            uploaduser:"no",
            bidcategory:"",
            inProgress: false,
            readnumbersummary : "no"

        };
        this.pathname = "uploaditemauction";
        this.engine = new RequestEngine();

        this.idFileBase64 = "";
        this.location=[];
        this.palletdepts=[];
        this.category=[];
        this.admincol=[]
        this.colmap = [];
        this.colmapsheetrelationship=[];
        this.allcols=[];
        this.readnumbersummary=[]

        this.provalue = 5;
    }

    componentDidMount() {

        const edit = this.props.location.data
        if (edit){
            this.setState({
                ...edit
            });
        }
        //  this.checkPermission();
        this.prepareData()
    }

    async prepareData(){
        this.setState({
            isLoading:true
        })


        let responseuni = await this.engine.getItemlistDirect("bidcategory");
        if(responseuni){
            const data = responseuni.data.data;
            let bidcategorylist = []
            data.map(p =>{
                bidcategorylist.push( { value: p.id, label: p.name})
                }
            )
            this.setState({
                bidcategorylist,
                internalload:false
            });
        }





        this.setState({
            isLoading:false
        })





    }




    checkPermission(){
        const managertype = localStorage.getItem("managertype");
        if(!managertype || managertype=="normaluser"){
            this.props.history.push('/admin/notfound');
        }
    }



    handleValidSubmit = async () => {
        this.setState({
            loading: true
        })
        let data = {...this.state}


        data.idFileBase64 = this.idFileBase64;
        data.location =  this.location;
        data.palletdepts =  this.palletdepts;
        data.category =  this.category;
        data.admincol =  this.admincol;
        data.colmap = this.colmap;
        data.allcols = this.allcols;
        data.readnumbersummaryselection = this.readnumbersummary;

        data.colmapsheetrelationship = this.colmapsheetrelationship;


        const progresslimit = data.category>60?20:10;
        let config = {
            responseType: 'stream',
            onDownloadProgress: progressEvent => {
                const progressupdate = this.state.progress+progresslimit;
                if(progressupdate>100){
                    return;
                }
                this.setState({
                    progress:progressupdate
                })
                this.provalue =  this.provalue+progresslimit


            }
        };

        this.setState({
            inProgress:true
        })

        const response = await  this.engine.postItemWithCustomConfigData(this.pathname,"/save",data,config);


        if(response && response.status === 200){
            Utilites.showSucessMessage("Uploaded !")
            this.props.history.push('/admin/adminsummary');
        }

        this.setState({
            loading: false,
            inProgress:false
        })

    };




    // get main column
    processsheetdata(wsname,fillcoldefault=false){
        const ws = this.state.readedData.Sheets[wsname];
        const dataParse = XLSX.utils.sheet_to_json(ws, {header:1});
        let locationIndex = "";
        let palletdeptsIndex = "";
        let categoryIndex = "";

        if(dataParse && dataParse[0]){
            let admincollist = [];
            dataParse[0].map((p,index) =>{
                    admincollist.push( { value: p, label: p})
                const colfound = this.allcols.find(x=>x==p)
                if(p.toString().toLowerCase()=="warehouse"){
                    locationIndex = index;
                }
                if(p.toString().toLowerCase()=="stockcode"){
                    palletdeptsIndex = index;
                }
                if(p.toString().toLowerCase()=="inventory category"){
                    categoryIndex = index;
                }
                if(p && !wsname.toString().toLowerCase().includes("summary")){

                    let correctvalue = p.split(" ").join("").toString().toLowerCase()
                    correctvalue =  this.autoCleanValue(correctvalue);
                    if(!colfound && correctvalue){
                        this.allcols.push(p);
                        // add sheet name
                        this.colmap.push({ value: correctvalue})
                    }
                    const colfoundx = this.colmapsheetrelationship.find(x=>x.value==correctvalue && x.sheet==wsname )
                    if(!colfoundx && correctvalue){
                        this.colmapsheetrelationship.push({ value: correctvalue,sheet:wsname})
                    }
                }



                }
            )
            return {
                admincollist:admincollist,
                location:dataParse[1][locationIndex],
                palletdepts:dataParse[1][palletdeptsIndex],
                category:dataParse[1][categoryIndex]
            }
        }
    }
    onChangePic(e) {
        e.preventDefault();
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        Utilites.getFileBase64(e.target.files[0], (result) => {
            this.idFileBase64 = result;
        });
        reader.onload =  (e)=> {
            var data = e.target.result;
            let readedData = XLSX.read(data, {type: 'binary'});

            this.location=[];
            this.palletdepts=[];
            this.category=[];
            this.admincol=[]
            this.setState({
                sheetlist:readedData.SheetNames,
                readedData:readedData
            },()=>{
                readedData.SheetNames.map(xx=>{
                    if(2>1 || !xx.toString().toLowerCase().includes("summary")){
                        const values = this.processsheetdata(xx);
                        this.location.push({value:values.location})
                        this.palletdepts.push({value:values.palletdepts})
                        this.category.push({value:values.category})
                        let defaultcolmn = []
                        let colfound = values.admincollist.find(x=>x.value.toLowerCase()=="pallet");
                        if(colfound){
                            defaultcolmn.push({value: colfound.value, label: colfound.value})
                        }
                        colfound = values.admincollist.find(x=>x.value.toLowerCase()=="serial");
                        if(colfound){
                            defaultcolmn.push({value: colfound.value, label: colfound.value})
                        }
                        this.admincol.push({value:defaultcolmn})
                        this.readnumbersummary.push({value:"0"})
                    }
                })

                Utilites.setTimeout(()=>{
                    this.forceUpdate()
                },300)
            })


        };
        reader.readAsBinaryString(f)

    }


    milestoneChangeValue = (event, stateName,index,subindex,takesubindex = false,addattrid=false) => {
        let servicefound = this[stateName][index]
        if(takesubindex){
            servicefound = this[stateName].find(x=>x.key=="variationattributevalue"+index+"x"+subindex)
        }
        let value = ""
        if(event && event.target){
            value = event.target.value;
        }else if(event && event.length){
            value = event;
        }else{
            value = event?event.value:[];
        }
        if(servicefound){
            servicefound.value =  value;
        }else{
            if(takesubindex){
                this[stateName].push({key:stateName+index+"x"+subindex,value:value,addattrid:addattrid? this.attributename[subindex].value:undefined})
            }else{
                this[stateName].push({key:stateName+index,value:value,addattrid:addattrid? this.attributename[subindex].value:undefined})
            }
        }
        this.forceUpdate()
    };

    renderSheetInfo(){
        const {sheetlist,readnumbersummary}= this.state;

        return   <Table responsive>
            <thead>
            <tr>
                <th style={{width:400}}>Sheet Name</th>
                <th>Column</th>
                <th>Warehouse</th>
                <th>Stockcode</th>
                <th>Category</th>
                {readnumbersummary=="yes" && <th>Summary Number</th>}
            </tr>
            </thead>
            <tbody>


            {sheetlist.map((xx,index)=>{
                if(xx.toString().toLowerCase().includes("summary")){
                    return <></>;
                }
                const values = this.processsheetdata(xx,true);


                let  value = this.category && this.category[index]
                let category = values.category
                if(value && value.value){
                    category =  value.value;
                }

                value = this.palletdepts && this.palletdepts[index]
                let palletdepts = values.palletdepts
                if(value && value.value){
                    palletdepts =  value.value;
                }

                value = this.location && this.location[index]
                let location = values.location
                if(value && value.value){
                    location =  value.value;
                }

                value = this.admincol && this.admincol[index]

                let admincollist = values.admincollist
                let admincol= []
                if(value && value.value){
                    admincol =  value.value;
                }

                value = this.readnumbersummary && this.readnumbersummary[index]

                let readnumbersummaryvalue = ""
                if(value && value.value){
                    readnumbersummaryvalue =  value.value;
                }
                return    <tr>
                    <td> <FormRow hidelabel={true}  viewonly={true} placeholder={"sheet"} name={"sheet"+index} data={xx} /></td>
                    <td> <SelectRow  hidelabel={true} isMulti={true} name={"admincol"+index}  defaultValue={admincol}  data={admincollist} changeInput={(e)=>this.milestoneChangeValue(e,"admincol",index)} /></td>
                    <td> <FormRow hidelabel={true} placeholder={"location"} name={"location"+index} data={location}   changeInput={(e)=>this.milestoneChangeValue(e,"location",index)} /></td>
                    <td> <FormRow hidelabel={true} placeholder={"palletdepts"} name={"palletdepts"+index} data={palletdepts}   changeInput={(e)=>this.milestoneChangeValue(e,"palletdepts",index)} /></td>
                    <td> <FormRow hidelabel={true} placeholder={"category"} name={"category"+index} data={category}   changeInput={(e)=>this.milestoneChangeValue(e,"category",index)} /></td>
                   {readnumbersummary=="yes" && <td> <FormRow hidelabel={true} placeholder={"readnumbersummary"} name={"readnumbersummary"+index} data={readnumbersummaryvalue}   changeInput={(e)=>this.milestoneChangeValue(e,"readnumbersummary",index)} /></td>}
                </tr>
            })}



            </tbody>
        </Table>
    }


    autoFixColValue(colmap,value){
        if(value && value.value){
            colmap =  this.autoCleanValue(value.value);
        }
        return colmap;
    }


    renderColInfo(){
        return   <Table responsive id={"selectred"}>
            <thead>
            <tr>
                <th style={{width:300}}>COLUMN Name</th>
                <th>Maping Column</th>
            </tr>
            </thead>
            <tbody>


            {this.allcols.map((xx,index)=>{
                if(xx.toString().toLowerCase().includes("summary")){
                    return <></>;
                }


                let  value = this.colmap && this.colmap[index]
                let colmap = xx.toString().trim().toLowerCase()

                if(SKIPPEDCOLS.includes(colmap)){
                    return <></>;
                }
                colmap = this.autoFixColValue(colmap,value)

                return    <tr>
                    <td> <FormRow hidelabel={true}  viewonly={true} placeholder={"sheet"} name={"sheet"+index} data={xx}    /></td>
                    <td><SelectRow   hidelabel={true}  name={"colmap"+index}  defaultValue={colmap}  data={this.createSelectValue(mapedInput)} changeInput={(e)=>this.milestoneChangeValue(e,"colmap",index)} /></td>

                </tr>
            })}

            </tbody>
        </Table>
    }


    renderstep1() {
        // taking all the states
        const {loading,isLoading,readedData,readnumbersummary}= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <Card>
                    <CardHeader>
                        <CardTitle tag="h4"> information</CardTitle>

                        <a href={"/auctionexample.xlsx"}>Download XLSX example</a>
                        <br/>
                        <span style={{color:"red"}}>if the file is big it make take up to 15mb</span>
                    </CardHeader>
                    <CardBody>

                        {readedData && this.renderSheetInfo()}
                        {!readedData && <Row>
                            <Label sm="2">XLSX   </Label>
                            <Col sm="5">
                                <FormGroup >
                                    <ImageUpload placeholder={this.state.fullpicture} labelupload="Select File" isfile={true} onChange={(e) => this.onChangePic(e)}/>
                                </FormGroup>
                            </Col>
                        </Row>}


                        {2>5 && !readedData && <SelectRow  label={"Read from summary row"} name={readnumbersummary}  defaultValue={readnumbersummary}  data={this.createSelectValue(["yes","no"])}  changeInput={(e)=>{
                            this.setState({
                                readnumbersummary:e.value
                            })
                        }}  />}




                    </CardBody>


                    <CardFooter className="text-center">
                        <ButtonLoader onClick={()=>{
                            window.scrollTo(0, 0);
                            this.setState({
                                step:2
                            })
                        }} color="primary" loading={loading}>Next</ButtonLoader>

                    </CardFooter>
                </Card>
            </>
        );
    }

    renderstep2() {
        // taking all the states
        const {loading,isLoading}= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <Card>
                    <CardHeader>
                        <CardTitle tag="h4">Column Section </CardTitle>
                    </CardHeader>
                    <CardBody>
                        {this.renderColInfo()}
                    </CardBody>

                    <CardFooter className="text-center">
                        <ButtonLoader onClick={()=>{
                            this.setState({
                                step:1
                            })
                        }} color="orange" loading={loading}>Back</ButtonLoader>&nbsp;&nbsp;
                        <ButtonLoader onClick={()=>{
                            window.scrollTo(0, 0)
                            this.setState({
                                step:3
                            })
                        }} color="primary" loading={loading}>Next</ButtonLoader>

                    </CardFooter>
                </Card>
            </>
        );
    }

    renderstep3() {
        // taking all the states
        const {loading,isLoading,title,from,to,bidcategory,bidcategorylist}= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <Card>
                    <CardHeader>
                        <CardTitle tag="h4">Upload Info </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <FormRow label="title" name="title" data={title}   changeInput={this.changeInput.bind(this)} />
                        <SelectRow  label={"bids category"} name={bidcategory}  defaultValue={bidcategory}  data={bidcategorylist}  changeInput={(e)=>{
                            this.setState({
                                bidcategory:e.value
                            })
                        }}  />
                        <FormRow label="from date" name="from" data={from} type={"datetime-local"}   changeInput={this.changeInput.bind(this)} />
                        <FormRow label="to date" name="to" data={to}   type={"datetime-local"} changeInput={this.changeInput.bind(this)} />
                    </CardBody>

                    <CardFooter className="text-center">
                        <ButtonLoader onClick={()=>{
                            this.setState({
                                step:2
                            })
                        }} color="orange" >Back</ButtonLoader>&nbsp;&nbsp;
                        <ButtonLoader  color="primary" loading={loading}>Submit</ButtonLoader>
                    </CardFooter>
                </Card>
            </>
        );
    }
    render() {
        // taking all the states
        const {step,loading,isLoading}= this.state;

        if (isLoading) {
            return this.renderProgress()
        }

        if(this.state.inProgress){
            return <div className="content">
                <ProgressBar
                    id="progress-bar-status"
                    className={this.provalue == 100 ? 'complete' : '' }
                    width="100%"
                    min={0}
                    max={100}
                    statusFormat={statusFormat}
                    value={this.provalue}
                />

            </div>
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                {step==1 && this.renderstep1()}
                                {step==2 && this.renderstep2()}
                                {step==3 && this.renderstep3()}

                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


