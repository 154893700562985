import React from "react";
import DateCountdown from 'react-date-countdown-timer';
// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";

import Tbl from "../contact/tbl/Tbl";
import SelectRow from "../../components/Select/SelectRow";

class ReadOnlySummary extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            innerisLoading:true,
            dataSource: [],
            defaultexpand:[],
            itemgroupinfo:{},
            group:"0",
            grouplist:[],
        };
        this.pathname = "itemgroups";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.callPage()
        this.callPageGroup();

    }
    async callPageGroup() {
        this.setState({
            innerisLoading: true,
        })
        try{
            let response = await  this.engine.directpostItem("archiveitemgroup","/list/0");

            if(response && response.status === 200){
                const data = response.data.data.data;
                let grouplist = []
                data.map(p =>{
                        grouplist.push( { value: p._id, label: p.title+ " "+ this.renderDate(p.from,"YYYY-MM-D HH:mm")+" >> "+this.renderDate(p.to,"YYYY-MM-D HH:mm")})
                    }
                )
                this.setState({
                    grouplist,
                    group:grouplist.length ? grouplist[0].value:"0"
                })
            }
        }catch (e) {
            if(e.response.status==401){
                this.props.history.push('/auth/login');
            }
        }

        this.props.loadingAction(false);
    }

    async callPage() {
        this.setState({
            innerisLoading: true,
        })
        let response = await  this.engine.getItem(this.pathname);
        if(response && response.status === 200){
            this.setState({
                    isLoading: false,
                innerisLoading:false,
                    defaultexpand:response.data.data.defaultexpand,
                    dataSource: response.data.data.items,
                    itemgroupinfo:response.data.data.itemgroupinfo,
                }
            );
        }

        this.props.loadingAction(false);
    }

    handlePage() {
        this.props.loadingAction(true);
        this.callPage()
    }


    handleAdd() {
        this.props.history.push('/admin/'+this.pathname+'/add');
    }


    render() {
        const {dataSource,isLoading,defaultexpand,grouplist,group,innerisLoading} = this.state;



        if(isLoading){
            return this.renderProgress();
        }



        return (
            <>

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" >Archived Summary </CardTitle>

                                    <div className="">
                                        <SelectRow label="Item Group"  name="group"  defaultValue={group}  data={grouplist} changeInput={(e)=>{

                                            this.setState({
                                                group:e.value
                                            },()=>this.callPage())

                                        }} />
                                    </div>

                                </CardHeader>


                                <CardBody>
                                    <Tbl innerisLoading={innerisLoading} data={dataSource} readonly={true} defaultexpand={defaultexpand} />

                                </CardBody>


                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(ReadOnlySummary);
