import React from "react";
// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import Tbl from "./TblAdmin";
import SelectRow from "../../components/Select/SelectRow";
import {ProgressBar} from "devextreme-react/progress-bar";
import {Utilites} from "../../core/Utilites";
import LoadingOverlay from "react-loading-overlay";

function statusFormat(value) {
    return `Loading: ${parseFloat(value * 100).toFixed(0)}%`;
}

class AdminSummary extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            innerisLoading:true,
            overlayLoading:false,
            dataSource: [],
            group:"0",
            grouplist:[],
            defaultexpand:[],
            users:[],
            progress:5,
            maxValue : 10,
            seconds: 10,
            buttonText: 'Start progress',
            inProgress: false,
            showadvancedloader:true,
        };
        this.pathname = "progressitemgroupsadmin";
        this.engine = new RequestEngine();
        this.provalue = 5;
        this.checkPermission();
    }

    componentDidMount() {
        this.callPage()
    }

    checkPermission(){
        const managertype = localStorage.getItem("managertype");
        if(!managertype || managertype=="normaluser"){
            this.props.history.push('/admin/notfound');
        }
    }



    async callPage() {

        this.setState({
            innerisLoading: true,
        })

        let config = {
            responseType: 'stream',
            onDownloadProgress: progressEvent => {
                const progressupdate = this.state.progress+10;
                if(progressupdate>100){
                    return;
                }
                this.setState({
                    progress:progressupdate
                })
                this.provalue =  this.provalue+10
            }
        }
        let response = await  this.engine.getItemWithCustomConfig(this.pathname,"/list/"+this.state.group,config);


        if(response && response.status === 200){

            try{
                let result = response.data.replace(/{"done":false}/g, "");
                result = result.replace("\"done\":false,", "");
                let test = result.split("data: {");
                let updatedresult = []
                if(test.length==2){
                    test = test[1].replace("\"done\":false,", "");
                    test = test.replace("\"done\":true,", "");
                    test = "{"+test;
                     updatedresult = JSON.parse(test);
                }else{
                    updatedresult = JSON.parse(result);
                }
                debugger

                this.setState({
                        isLoading: false,
                        innerisLoading:false,
                        defaultexpand:updatedresult.data.defaultexpand,
                        dataSource: updatedresult.data.items,
                        users:updatedresult.data.users,
                        group:updatedresult.data.group
                    }
                );
            }catch (e) {

                this.setState({
                        isLoading: false,
                        innerisLoading:false,
                        defaultexpand: [],
                    dataSource:[],
                    users:[],
                        group:[]
                    }
                );
            }


            Utilites.setTimeout(()=>{
                this.setState({
                    showadvancedloader : false
                })
            },600)
        }




        response = await  this.engine.directpostItem("allitemgroup");


        if(response && response.status === 200){


            const data = response.data.data;
            let grouplist = []
            data.map(p =>{
                    grouplist.push( { value: p._id, label: p.title+ " "+ this.renderDate(p.from,"YYYY-MM-D HH:mm")+" >> "+this.renderDate(p.to,"YYYY-MM-D HH:mm")})
                }

            )
            this.setState({
                grouplist
            })
        }
        this.props.loadingAction(false);
    }




    async exportselection(selected){

        const {group} = this.state
        selected.map(x=>{
             x.itemgroup=group
            return x;
        })

        this.setState({
            overlayLoading:true
        })
      await this.engine.exportLocationSelectionCSVx("itemgroupselection",selected);
        this.setState({
            overlayLoading:false
        })
    }
    async callPagecat4() {


        this.setState({
            innerisLoading: true,
        })

        let config = {
            responseType: 'stream',
            onDownloadProgress: progressEvent => {
                const progressupdate = this.state.progress+10;
                if(progressupdate>100){
                    return;
                }
                this.setState({
                    progress:progressupdate
                })
                this.provalue =  this.provalue+10
            }
        }
        let response = await  this.engine.getItemWithCustomConfig(this.pathname,"/list/"+this.state.group+"/WE",config);

        if(response && response.status === 200){

            try{
                let result = response.data.replace(/{"done":false}/g, "");
                result = result.replace("\"done\":false,", "");
                const updatedresult = JSON.parse(result);
                this.setState({
                        isLoading: false,
                        innerisLoading:false,
                        defaultexpand:updatedresult.data.defaultexpand,
                        dataSource4: updatedresult.data.items,
                        group:updatedresult.data.group
                    }
                );
            }catch (e) {
                this.setState({
                        isLoading: false,
                        innerisLoading:false,
                        defaultexpand: [],
                    dataSource4:[],
                        group:[]
                    }
                );
            }


            Utilites.setTimeout(()=>{
                this.setState({
                    showadvancedloader : false
                })
            },600)
        }



        response = await  this.engine.directpostItem("itemgroup","/list/0");


        if(response && response.status === 200){


            const data = response.data.data.data;
            let grouplist = []
            data.map(p =>{
                    grouplist.push( { value: p._id, label: p.title+ " "+ this.renderDate(p.from,"YYYY-MM-D HH:mm")+" >> "+this.renderDate(p.to,"YYYY-MM-D HH:mm")})
                }

            )
            this.setState({
                grouplist
            })
        }
        this.props.loadingAction(false);
    }



    handleAdd() {
        this.props.history.push('/admin/'+this.pathname+'/add');
    }


    renderCat1() {
        const {dataSource,defaultexpand,innerisLoading,users} = this.state;

        return (
            <>

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <Tbl users={users} exportselection={this.exportselection.bind(this)} innerisLoading={innerisLoading} readonly={false} data={dataSource} defaultexpand={defaultexpand}/>

                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }

    renderCat2(title) {
        const {dataSource2,defaultexpand,innerisLoading} = this.state;

        return (
            <>

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <h6>{title}</h6>
                                <Tbl innerisLoading={innerisLoading} readonly={false} data={dataSource2} defaultexpand={defaultexpand}/>

                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }

    renderCat3(title) {
        const {dataSource3,defaultexpand,innerisLoading} = this.state;

        return (
            <>

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <h6>{title}</h6>
                                <Tbl innerisLoading={innerisLoading} readonly={false} data={dataSource3} defaultexpand={defaultexpand}/>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }

    renderCat4(title) {
        const {dataSource4,defaultexpand,innerisLoading} = this.state;

        return (
            <>

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <h6>{title}</h6>
                                <Tbl innerisLoading={innerisLoading} readonly={false} data={dataSource4} defaultexpand={defaultexpand}/>

                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }

    render() {
        const {overlayLoading,isLoading,grouplist,group} = this.state;

        if(this.state.showadvancedloader){
            return <div className="content">
                <ProgressBar
                    id="progress-bar-status"
                    className={this.provalue == 100 ? 'complete' : '' }
                    width="100%"
                    min={0}
                    max={100}
                    statusFormat={statusFormat}
                    value={this.provalue}
                />

            </div>
        }

        if(isLoading){
            return this.renderProgress();
        }



        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" >Admin Summary</CardTitle>

                                    <div className="">
                                        <SelectRow label="Item Group"  name="group"  defaultValue={group}  data={grouplist} changeInput={(e)=>{
                                            this.setState({
                                                group:e.value
                                            },()=>this.callPage())

                                        }} />
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <LoadingOverlay
                                        active={overlayLoading}
                                        spinner
                                        text='Loading ...'
                                    >
                                    {this.renderCat1()}
                                    </LoadingOverlay>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(AdminSummary);
