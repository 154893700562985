import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';

import nationalityList from "../../components/Select/Nationality"
import Constants from "../../core/Constants";
import {Utilites} from "../../core/Utilites";

export default class ItemGroupAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            _id:"",
            from:"",
            to:"",
            title:"",
            picture:"",
            loading : false
        };
        this.pathname = "itemgroupupdate";
        this.engine = new RequestEngine();


        this.idFileBase64 = "";
    }

    componentDidMount() {


        const edit = this.props.location.data
        if (edit){
            this.setState({
                ...edit,
                to:this.renderDate(edit.to,"YYYY-MM-DDTHH:mm"),
                from:this.renderDate(edit.from,"YYYY-MM-DDTHH:mm") //
            });
        }
        this.checkPermission();
    }


    checkPermission(){
        const managertype = localStorage.getItem("managertype");
        if(!managertype || managertype=="normaluser"){
            this.props.history.push('/admin/notfound');
        }
    }



    handleValidSubmit = async () => {


        this.setState({
            loading: true
        })

        const response = await this.engine.saveItem("itemgroupupdate",this.state)

        if(response && response.status === 200){
            this.props.history.push('/admin/itemgroup');
        }
        this.setState({
            loading: false
        })

    };

    onChangePic(e) {
        this.setState({picture:e.target.files[0]})
    }

    onChangePicar(e) {
        Utilites.getFileBase64(e.target.files[0], (result) => {
            this.idFileBase64 = result;
        });
    }
    render() {
        // taking all the states
        const {loading,isLoading,from ,to,title }= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4"> information</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <FormRow label="title" name="title" data={title}    changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="from date" name="from" data={from} type={"datetime-local"}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="to date" name="to" data={to}   type={"datetime-local"} changeInput={this.changeInput.bind(this)} />

                                    </CardBody>







                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>Save</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


