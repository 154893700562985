import {SET_BIDS} from "../actionTypes"

const initalState ={
    bids:[] // taskid , and value
}

export default function bidReducer(state = initalState, action) {
    switch (action.type) {
        case SET_BIDS:

            let bids =  state.bids
            let passparameter = action.payload;
            let bidfound = bids.find(i=>i.taskid==passparameter.taskid);
            if(!bidfound){
                bids.push(passparameter)
            }
            return {...state, bids: bids};
        default:
            return state;
    }
}
