import React from 'react';
import Constants from "../../core/Constants";

export default function PalletTemplate(options) {
    const key = options.column.dataField
    const bidaction = options.data[key];

    if (!bidaction) {
        return <span className="name"></span>;
    }
    if(key=="pallet" && !bidaction.toString().includes("Total")){
        return <span className="name">{bidaction}</span>;
    }
    return (
        <React.Fragment>
            <span style={{
                background: "grey",
                color: "white",
                fontWeight:"bold",
                padding:3
            }} >{bidaction}</span>
        </React.Fragment>
    );
}
