import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col
} from "reactstrap";
import {connect} from "react-redux";
import CoreEngine from "../core/CoreEngine";

class NotFound extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            dataSource: [],
            rand:0,
        };
    }







    render() {

        return (
            <>

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" className="pull-left">Error</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <h4>Page not found</h4>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}


const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
   // loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
