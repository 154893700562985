import React from 'react';
import Constants from "../../core/Constants";

export default function ViewBidCell(options) {

    const bidaction = options.data.bidaction;
    if (!bidaction) {
        return <span className="name">-</span>;
    }
    return (
        <React.Fragment>
            <a href={Constants.sitelink+"admin/summarybids/"+options.data.bidlink} target={"_blank"} ><span className="name">{bidaction}</span></a>
        </React.Fragment>
    );
}
