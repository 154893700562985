import { combineReducers } from 'redux'
import loadingReducer from './loadingReducer'
import showMsgReducer from './showMsgReducer'
import bidReducer from './bidReducer'

export default combineReducers({
    progress : loadingReducer,
    showmsg : showMsgReducer,
    bids:bidReducer
})
