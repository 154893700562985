import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    DropdownItem,
    UncontrolledDropdown,
    PaginationItem, PaginationLink, Pagination,
    DropdownMenu,
    DropdownToggle, Button
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import ReactTable from "react-table";
import {Utilites} from "../../core/Utilites";

class ItemGroupList extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
            page:0,
            count:0,
            pageSize:10,
            innerloading:true,
        };
        this.pathname = "itemgroup";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.checkPermission();
    }


    checkPermission(){
        const managertype = localStorage.getItem("managertype");
        if(!managertype || managertype=="normaluser"){
            this.props.history.push('/admin/notfound');
        }
    }


    async handleDelete(valueid) {
        if(window.confirm("Are you sure you want to delete this item group ? (all itemgroup items and auction will be deleted also} ")) {
            this.props.loadingAction(true)
            const result = await this.engine.deleteItem(this.pathname, valueid)
            if(result.status === 200 && result.data.success){
                this.handlePage(0);
            }else{
                Utilites.showErrorMessage(result.data.message)
            }
            this.props.loadingAction(false)
        }

    }

    async handleEmptyauction(valueid) {
        if(window.confirm("Are you sure you want to empty the auction of this item group ?")){
            this.props.loadingAction(true)
            const result = await this.engine.emptyauctionItem(this.pathname,valueid)
            if(result){
                this.handlePage(0);
            }
            this.props.loadingAction(false)
        }


    }


    handleEdit(index,dataid) {
        const data = this.state.dataSource[index].data

        this.props.history.push({
            pathname:'/admin/'+this.pathname+'/edit/'+dataid,
            data
        });

    }


    handleView(dataid) {
        this.props.history.push({
            pathname:'/admin/itemgroupviewitems/'+dataid,
        });
    }

    handleViewBid(dataid) {
        this.props.history.push({
            pathname:'/admin/itemgroupviewbids/'+dataid,
        });
    }

    handleActions(index,item) {

        return (<UncontrolledDropdown>
            <DropdownToggle
                aria-expanded={false}
                aria-haspopup={true}
                caret
                className="btn-round btn-block"
                color="primary"
                data-toggle="dropdown"
                id="dropdownMenuButton"
                type="button"
            >
                Actions
            </DropdownToggle>
            <DropdownMenu>

                <DropdownItem  eventKey="2" onClick={() => this.handleEdit(index,item._id)}>Edit</DropdownItem>
                <DropdownItem  eventKey="2" onClick={() => this.handleView(item._id)}>View Item Details </DropdownItem>
                <DropdownItem  eventKey="2" onClick={() => this.handleViewBid(item._id)}>View Bid</DropdownItem>
                <DropdownItem variant="danger" eventKey="1" onClick={() => this.handleDelete(item._id)}>Delete</DropdownItem>
                <DropdownItem variant="danger" eventKey="1" onClick={() => this.handleEmptyauction(item._id)}>Empty Auction</DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>)
    }






    async callPage(page,pageSize=10,filtered = [],sorted=[]) {
        this.setState({
            innerloading : true,
        })
        const response = await  this.engine.postItem(this.pathname,"/list/"+page+"/"+pageSize,filtered,sorted);



        if(response && response.status === 200){
            this.setState({ // cname,cemail,cposition,cdesc,category,nationality,address,website
                    isLoading: false,
                innerloading:false,
                pageSize:pageSize,
                    count:response.data.data.count,
                    page:page,
                    dataSource: response.data.data.data.map((item, key) => {
                        return {
                            id: key,
                            data:item,
                            title:item.title,
                            bidcategory:item.bidcategory && item.bidcategory.name,
                            from:this.renderDate(item.from,"YYYY-MM-D HH:mm"),
                            to: this.renderDate(item.to,"YYYY-MM-D HH:mm") ,
                            createdAt:this.renderDate(item.createdAt),
                            actions: (
                                // we've added some custom button actions
                                this.handleActions(key,item)
                            )
                        };
                    })
                }
            );
        }
        this.props.loadingAction(false);
    }

    handlePage() {
        this.props.loadingAction(true);
        this.callPage(0)
    }


    handleAdd() {
        this.props.history.push('/admin/'+this.pathname+'/add');
    }


    render() {
        const {dataSource,pageSize,innerloading,count} = this.state;

        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= count; i++) {
            pageNumbers.push(i);
        }
        const renderPageNumbers = pageNumbers.map((number, index) => {
            return (
                <PaginationItem   key={index}>
                    <PaginationLink
                        onClick={()=>this.callPage(number,pageSize)}
                    >
                        {number}
                    </PaginationLink>
                </PaginationItem>
            );
        })


        let columns = [
            {
                Header: "title",
                accessor: "title"
            },
            {
                Header: "bid category",
                accessor: "bidcategory"
            },
            {
                Header: "from",
                accessor: "from"
            },
            {
                Header: "to",
                accessor: "to"
            },

            {
                Header: "Created",
                accessor: "createdAt",

            },
            {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false
            }
        ]


        return (
            <>

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" className="pull-left">Auction</CardTitle>
                                    <Button color="primary" className="pull-right" onClick={() =>
                                        this.props.history.push('/admin/'+this.pathname+'/upload')
                                    }>
                                        Create
                                    </Button>
                                </CardHeader>
                                <CardBody>
                                    <Pagination
                                        className="pagination pagination-primary"
                                        listClassName="pagination-primary"
                                        style={{    overflow: "scroll"}}
                                    >
                                        {renderPageNumbers}
                                    </Pagination>
                                    <ReactTable
                                        data={dataSource}
                                        defaultFilterMethod={this.filterCaseInsensitive}
                                        filterable
                                        manual
                                        loading={innerloading}
                                        columns={columns}
                                        defaultPageSize={10}
                                        showPaginationTop
                                        onFetchData={(state, instance) => {
                                            this.setState({
                                                innerloading:true
                                            })
                                            this.callPage(state.page, state.pageSize,state.filtered,state.sorted)
                                        }}
                                        showPaginationBottom={false}
                                        className="-striped -highlight primary-pagination"
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(ItemGroupList);
