import React from "react";
import DateCountdown from 'react-date-countdown-timer';
// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col, Button,
} from "reactstrap";

import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import Tbl from "./tbl/Tbl";
import {ProgressBar} from "devextreme-react/progress-bar";

import LoadingOverlay from 'react-loading-overlay';
function statusFormat(value) {
    return `Loading: ${parseFloat(value * 100).toFixed(0)}%`;
}
class ContactList extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            loading1:true,
            overlayLoading:false,
            showadvancedloader:true,
            fulldata:[],
            dataSource: [],
            loading:true,
            group:"",
            defaultexpand:[],
            itemgroupinfo:{},
        };
        this.pathname = "itemgroups";
        this.engine = new RequestEngine();
        this.provalue = 5;
    }

    componentDidMount() {
        this.callPage()
    }

    async callPage() {

        let responseuni = await this.engine.getItemlistDirect("bidcategory");
        if(responseuni){
            let bidcategorylist = []


            const max = responseuni.data.data.data.length;
            for(let i=0;i<max;i++){
                await this.callPagecatDirect(responseuni.data.data.data[i].id)
                this.provalue += 100/max;
            }
            this.setState({
                bidcategorylist,
            });
        }


        this.provalue = 100;
        this.setState({
            showadvancedloader: false,
            isLoading: false,
            loading:false,
            internalload:false
            }
        );
        this.props.loadingAction(false);
    }


    async callPagecatDirect(categoryid) {
        try{
            const response = await  this.engine.getItem(this.pathname,"/list/"+categoryid);
            let {fulldata} = this.state

            if(response && response.status === 200){
                fulldata.push({
                    categoryid:categoryid,
                    title:response.data.data.title,
                    defaultexpand:response.data.data.defaultexpand,
                    dataSource: response.data.data.items,
                    itemgroupinfo:response.data.data.itemgroupinfo,
                })
                this.setState({
                        fulldata,
                    }
                );
            }
        }catch (e) {
            if(e.response.status==401){
                this.props.history.push('/auth/login');
            }
        }
    }

    handlePage() {
        this.props.loadingAction(true);
        this.callPage()
    }


    handleAdd() {
        this.props.history.push('/admin/'+this.pathname+'/add');
    }

    async exportselection(selected){
       //
        const {itemgroupinfo1,itemgroupinfo2,itemgroupinfo3,itemgroupinfo4,itemgroupinfo5} = this.state
        let group = null;
        if(itemgroupinfo1){
            group =  itemgroupinfo1.id;
        }
        if(itemgroupinfo2){
            group =  itemgroupinfo2.id;
        }
        if(itemgroupinfo3){
            group =  itemgroupinfo3.id;
        }
        if(itemgroupinfo4){
            group =  itemgroupinfo4.id;
        }
        if(itemgroupinfo5){
            group =  itemgroupinfo5.id;
        }

        selected.map(x=>{
            x.itemgroup=group
            return x;
        })
        this.setState({
            overlayLoading:true
        })
         await this.engine.exportLocationSelectionCSVx("itemgroupselection",selected);
        this.setState({
            overlayLoading:false
        })
    }

    renderCat({title,dataSource,defaultexpand,itemgroupinfo}) {
        const {innerisLoading,loading} = this.state;
        if(loading){
           return  this.renderProgress()
        }

        return (
            <>

                <div className="content">



                    <Row>
                        <Col md="12">

                                 <Card>
                                    <h6>{title} </h6>
                                     {itemgroupinfo  && <DateCountdown dateTo={this.convertToCountdownformat(itemgroupinfo.to)} callback={()=>console.log('Hello')}  />}
                                    <Tbl exportselection={this.exportselection.bind(this)} innerisLoading={innerisLoading} readonly={false} data={dataSource} defaultexpand={defaultexpand}/>
                                </Card>


                        </Col>
                    </Row>

                </div>
            </>
        );
    }



    render() {
        const {isLoading,overlayLoading,fulldata} = this.state;

        if(isLoading || this.state.showadvancedloader){
            return <div className="content">
                <ProgressBar
                    id="progress-bar-status"
                    className={this.provalue == 100 ? 'complete' : '' }
                    width="100%"
                    min={0}
                    max={100}
                    statusFormat={statusFormat}
                    value={this.provalue}
                />

            </div>
        }

        return (
            <>

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" >Summary </CardTitle>

                                    <Button color="primary" className="pull-right" onClick={() =>
                                        this.props.history.push('/admin/myuser/upload')
                                    }>
                                        Upload Prices
                                    </Button>

                                </CardHeader>
                                <LoadingOverlay
                                    active={overlayLoading}
                                    spinner
                                    text='Loading ...'
                                >
                                <CardBody>
                                    {fulldata.map(item=>{
                                        if(item.dataSource.length){
                                            return  this.renderCat(item)
                                        }
                                    })}

                                </CardBody>
                                </LoadingOverlay>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(ContactList);
