import { createStore,applyMiddleware } from "redux";

import {  persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import thunk from 'redux-thunk';
import reducerlist from './reducers'

const persistConfig = {
    key: 'root092999',
    storage,
}


export function createStorageListener(store) {
    return event => {
        try{
            // the storage event tells you which value changed
            const {action} = JSON.parse(event.newValue);
            if(action) {

                store.dispatch(action);
            }
        }catch (e) {

        }

    }
}

const persistedReducer = persistReducer(persistConfig, reducerlist)



let store = createStore(persistedReducer,applyMiddleware(thunk))

window.addEventListener('storage', createStorageListener(store))

export default store;


