import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col,
    DropdownItem,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle, Pagination, PaginationItem, PaginationLink
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import ReactTable from "react-table";
import {Utilites} from "../../core/Utilites";

 class UserList extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
            page:0,
            count:0,
            pageSize:10,
            innerloading:true,
        };
        this.engine = new RequestEngine();
    }

    componentDidMount() {

        this.checkPermission();
    }


     checkPermission(){
         const managertype = localStorage.getItem("managertype");
         if(!managertype || managertype=="normaluser"){
             this.props.history.push('/admin/notfound');
         }
     }



     handleDelete(valueid) {
         console.log(valueid);

         const {manager} = this.props
         let myuserid = localStorage.getItem("userid");
         if(myuserid==valueid){
             Utilites.showErrorMessage("You cant delete your account");
             this.handlePage(manager);
             return;
         }
         this.props.loadingAction(true)
         if(manager){
             this.engine.deleteManager(valueid,(response) => {
                 console.log(response.status);
                 if(response.status === 200){
                     this.handlePage(manager);
                 }
                 this.props.loadingAction(false)
             });
         }else{
             this.engine.deleteUser(valueid,(response) => {
                 console.log(response.status);
                 if(response.status === 200){
                     this.handlePage(manager);
                 }
                 this.props.loadingAction(false)
             });
         }


     }


     handleEdit(index,dataid) {
         const data = this.state.dataSource[index].data
         const {manager} = this.props
         if(manager){
             this.props.history.push({
                 pathname:'/admin/manager/edit/'+dataid,
                 data
             });
         }else{
             this.props.history.push({
                 pathname:'/admin/user/edit/'+dataid,
                 data
             });
         }
     }


     handleDeleteAuctionByItemgroup(index,dataid) {
         const data = this.state.dataSource[index].data

             this.props.history.push({
                 pathname:'/admin/user/deleteuseritemgroup/'+dataid,
                 data
             });

     }


     async handleToggle(valueid,status) {
         if(window.confirm("Are you sure you ? ")) {
             this.props.loadingAction(true)
             const result = await this.engine.getItem("user", "/toggle/" + valueid + "/" + status)
             if (result) {
                 this.handlePage();
             }
             this.props.loadingAction(false)
         }
     }
     handleActions(index,item) {

         const {manager} = this.props
         return (<UncontrolledDropdown>
             <DropdownToggle
                 aria-expanded={false}
                 aria-haspopup={true}
                 caret
                 className="btn-round btn-block"
                 color="primary"
                 data-toggle="dropdown"
                 id="dropdownMenuButton"
                 type="button"
             >
                 Actions
             </DropdownToggle>
             <DropdownMenu>

                 <DropdownItem variant="danger" eventKey="1" onClick={() => this.handleDelete(item._id)}>{item.deleted && "Restore"} {!item.deleted && "Delete"}</DropdownItem>
                 <DropdownItem eventKey="1" onClick={() => this.handleEdit(index,item._id)}>Edit</DropdownItem>
                 <DropdownItem  eventKey="2" onClick={() => this.handleToggle(item._id,"suspended")} >Mark as Suspended </DropdownItem>
                 <DropdownItem  eventKey="3" onClick={() => this.handleToggle(item._id,"active")} >Mark as Active </DropdownItem>


             </DropdownMenu>
         </UncontrolledDropdown>)
     }


     toggelVerified(dataid) {
         const {manager} = this.props
         this.engine.toggleuserstatus(dataid._id,manager,(response) => {
             console.log(response.status);
             if(response.status === 200){
                 //this.iniload();
                 //window.location.reload()
                 this.componentDidMount()
             }
         });
     }
     checkstatus(item) {
        if(item.status === 0){

             return ("Unverified")

        }else if(item.status === 1){

            return ("Verified")
        }

     }


     putrowstatus(item){
         if(item.deleted){
             return "Deleted"
         }else
         if(item.status === 0){

             return "Unverified"

         }else if(item.status === 1){

             return "Verified"
         }

     }
     async callPage(page,pageSize=10,filtered = [],sorted=[]) {

         const manager = this.props.manager
         this.setState({
             innerloading : true,

         })

         try{
             const response = await  this.engine.postItem(manager?"manager":"user","/list/"+page+"/"+pageSize,filtered,sorted);

             if(response && response.status === 200){
                 this.setState({
                         isLoading: false,
                         innerloading:false,
                         count:response.data.data.count,
                         page:page,
                         pageSize:pageSize,
                         dataSource: response.data.data.data.map((item, key) => {
                             let bidcategory = [];
                             if(item.bidcategory){
                                 item.bidcategory.map(x=>{
                                     bidcategory.push(x.name);
                                 })
                             }
                             return {
                                 id: key,
                                 data:item,
                                 email: item.email ,
                                 bidcategory: bidcategory.join(" , "),
                                 status: item.status ,
                                 raw_status: item.status ,
                                 lastlogin:this.renderDate(item.lastlogin),
                                 subscribetype : item.subscribetype && item.subscribetype,
                                 username:item.username,
                                 phone: item.phone,
                                 createdAt:this.renderDate(item.createdAt),
                                 actions: (
                                     // we've added some custom button actions
                                     this.handleActions(key,item)
                                 )

                             };
                         })
                     }
                 );
             }
         }catch (e) {
             if(e.response.status==401){
                 this.props.history.push('/auth/login');
             }
         }
    }

     handlePage() {
         this.callPage()
     }


     handleAdd() {

         const {manager} = this.props

         if(manager){
             this.props.history.push('/admin/manager/add');
         }else{
             this.props.history.push('/admin/user/add');
         }

     }
     filterRawText(filter, row) {
         // Pivoted rows won't contain the column.
         //  If that's the case, we set the to true (allowing us to only filter on the current column)
         let rowValue = row[filter.id];
         if (!rowValue) {
             return true;
         }
         const index = row._index


         rowValue =  this.state.dataSource[index]["raw_"+filter.id]
         if(!rowValue){
             return;
         }
         const filterValue = filter.value;
         //const filterValue = filter.value.filterValue || "";
         //const filterType = filter.value.filterType;

         const filterType = "contains";
         switch (filterType) {
             case "contains":
                 return rowValue.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
             case "starts-with":
                 return rowValue.startsWith(filterValue);
             case "ends-with":
                 return rowValue.endsWith(filterValue);
             case "matches":
                 return rowValue === filterValue;
             case "greater-than":
                 return rowValue > filterValue;
             case "less-than":
                 return rowValue < filterValue;
             default:
                 return true;
         }
     }


     render() {

        const {dataSource,pageSize,innerloading,count} = this.state;
        const {manager} = this.props

         // Logic for displaying page numbers
         const pageNumbers = [];
         for (let i = 1; i <= count; i++) {
             pageNumbers.push(i);
         }
         const renderPageNumbers = pageNumbers.map((number, index) => {
             return (
                 <PaginationItem   key={index}>
                     <PaginationLink
                         onClick={()=>this.callPage(number,pageSize)}
                     >
                         {number}
                     </PaginationLink>
                 </PaginationItem>
             );
         })

        let columns = [
            {
                Header: "Username",
                accessor: "username"
            },
            {
                Header: "Email",
                accessor: "email",
                // width: 150
            },
            {
                Header: "bid category",
                accessor: "bidcategory",
                // width: 150
            },

            {
                Header: "Status",
                accessor: "status",
                filterMethod: this.filterRawText.bind(this),
            },
            {
                Header: "Created",
                accessor: "createdAt",

            },
            {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false
            }
        ]


         if(manager){
             columns = [
                 {
                     Header: "Username",
                     accessor: "username"
                 },
                 {
                     Header: "Email",
                     accessor: "email",
                 },
                 {
                     Header: "Last login",
                     accessor: "lastlogin",
                     // width: 150
                 },


                 {
                     Header: "Created",
                     accessor: "createdAt",

                 },
                 {
                     Header: "Actions",
                     accessor: "actions",
                     sortable: false,
                     filterable: false
                 }
             ]
         }


        return (
            <>

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" className="pull-left"> List</CardTitle>
                                    <Button color="primary" className="pull-right" onClick={() =>
                                        this.handleAdd()
                                    }>
                                        Add
                                    </Button>
                                </CardHeader>
                                <Pagination
                                    className="pagination pagination-primary"
                                    listClassName="pagination-primary"
                                    style={{    overflow: "scroll"}}
                                >
                                    {renderPageNumbers}
                                </Pagination>
                                <CardBody>
                                    <ReactTable
                                        data={dataSource}
                                        defaultFilterMethod={this.filterCaseInsensitive}
                                        filterable
                                        manual
                                        loading={innerloading}
                                        columns={columns}
                                        defaultPageSize={10}
                                        showPaginationTop
                                        onFetchData={(state, instance) => {
                                            this.setState({
                                                innerloading:true
                                            })
                                            this.callPage(state.page, state.pageSize,state.filtered,state.sorted)
                                        }}
                                        showPaginationBottom={false}
                                        className="-striped -highlight primary-pagination"
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(UserList);
