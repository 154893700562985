import React from 'react';
import { Template } from 'devextreme-react/core/template';
import TreeList, { Column, Selection, HeaderFilter } from 'devextreme-react/tree-list';
import viewbidTemplate from "./viewbidTemplate";
import CoreEngine from "../../core/CoreEngine";
import { CSVLink, CSVDownload } from "react-csv";
import {Export} from "devextreme-react/chart";
import {saveAs} from "file-saver-es";
import {exportDataGrid} from "devextreme/pdf_exporter";
import {Workbook} from "exceljs";
import PalletTemplate from "./PalletTemplate";
import viewUserTemplate from "./viewUserTemplate";
import PalletColorTempalte from "./PalletColorTempalte";


const headers = [
    { label: "Location", key: "location" },
    { label: "Pallet dept", key: "palletdepts" },
    { label: "Category", key: "category" },
    { label: "Quantity", key: "quantity" },
];

class TblAdmin extends CoreEngine {


    constructor(props) {
        super(props);
        this.state = {
            selectedData: [],
        };
        this.onExporting = this.onExporting.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
    }

    onSelectionChanged(e) {
        const selectedData = e.component.getSelectedRowsData("excludeRecursive");
        this.setState({
             selectedData,
        });
    }
    onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            customizeCell: function(options) {
                const excelCell = options;
                excelCell.font = { name: 'Arial', size: 12 };
                excelCell.alignment = { horizontal: 'left' };
            }
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
                });
        });
        e.cancel = true;
    }
    render() {

        const {selectedData} = this.state
        const {users} = this.props
        if(this.props.innerisLoading){
return <div style={{position: "relative",
    top: -100}}>{this.renderProgress()}</div>
        }
        return (<div>

            {selectedData.length==0?<CSVLink filename={"export.csv"} data={this.props.data} headers={headers}>
                    Export All CSV
                </CSVLink>:<a href={"#"} onClick={()=>{
                this.props.exportselection(selectedData)
                }
            } >
                Export Selection Only
            </a>}
                <TreeList
                    dataSource={this.props.data}
                    showBorders={true}
                    columnAutoWidth={true}
                    wordWrapEnabled={true}
                    onExporting={this.onExporting}
                    onSelectionChanged={this.onSelectionChanged}
                    defaultExpandedRowKeys={this.props.defaultexpand}
                    keyExpr="Task_ID"
                    parentIdExpr="Task_Parent_ID"
                    id="tasks"
                >
                    <Export enabled={true} />
                    <HeaderFilter visible={true} />
                    <Selection recursive={true} mode="multiple" />

                    <Column dataField="location"   cellTemplate={"PalletTemplate"} caption={"Location"} width={300} />
                    <Column
                        dataField="palletdepts"
                        caption="Product"
                        cellTemplate={"PalletTemplate"}
                    />
                    <Column
                        dataField="category"
                        caption="Category"
                        cellTemplate={"PalletTemplate"}
                    />
                    <Column
                        dataField="pallet"
                        caption="Pallets"
                        cellTemplate={"PalletTemplate"}
                    />

                    <Column
                        dataField="quantity"
                        caption="Quantity"
                    />




                    {users?.map(x=>{
                    return <Column dataField={x} caption={x}   cellTemplate="viewUserTemplate"  />
                    })}




                    <Column
                        dataField="bidaction"
                        caption="Bid Action"
                        cellTemplate="viewbidTemplate"
                    />




                    <Template name="viewUserTemplate" render={viewUserTemplate} />
                    <Template name="viewbidTemplate" render={viewbidTemplate} />
                    <Template name="PalletTemplate" render={PalletTemplate} />
                    <Template name="PalletColorTempalte" render={PalletColorTempalte} />

                </TreeList>
        </div>

        );
    }
}





export default TblAdmin;
