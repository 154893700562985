import React from 'react';
import { Template } from 'devextreme-react/core/template';
import TreeList, { Column, ColumnChooser, HeaderFilter, SearchPanel, Selection } from 'devextreme-react/tree-list';
import bidTemplate from "./bidTemplate";
import readonlybidTemplate from "./readonlybidTemplate";
import CoreEngine from "../../../core/CoreEngine";
import {CSVLink} from "react-csv";

class Tbl extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            selectedData: [],
        };
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
    }
    onSelectionChanged(e) {
        let selectedData = e.component.getSelectedRowsData("all");
        selectedData = selectedData.filter(x=>x.category!="");
        this.setState({
            selectedData,
        });
    }
    render() {
        if(this.props.innerisLoading){
            return <div style={{position: "relative",
                top: -100}}>{this.renderProgress()}</div>
        }
        return (
            <div>
                {this.state.selectedData.length>0 &&<a href={"#"} onClick={()=>{
                    this.props.exportselection(this.state.selectedData)
                }
                } >
                    Export Selection Only
                </a>}

            <TreeList
                dataSource={this.props.data}
                showBorders={true}
                columnAutoWidth={true}
                wordWrapEnabled={true}
                readonly={this.props.readonly}
                defaultExpandedRowKeys={this.props.defaultexpand}
                onSelectionChanged={this.onSelectionChanged}
                keyExpr="Task_ID"
                parentIdExpr="Task_Parent_ID"
                id="tasks"
            >
                <HeaderFilter visible={true} />
                <Selection recursive={true} mode="multiple" />
                <Column dataField="location" caption={"Location"} width={300} />
                <Column
                    dataField="palletdepts"
                    caption="Stock Code"
                />
                <Column
                    dataField="category"
                    caption="Inventory Category"
                />
                <Column
                    dataField="pallet"
                    caption="Pallets"
                />
                <Column
                    dataField="condition"
                    caption="Condition"
                />
                <Column
                    dataField="quantity"
                    caption="Quantity"
                />
                <Column
                    dataField="bidaction"
                    caption="Bid Action"
                    cellTemplate="bidTemplate"
                />
                <Column
                    dataField="sum"
                    caption="Current Bid"
                />

                {!this.props.readonly && <Column
                    dataField="status"
                    caption="Status"
                />}

                <Template name="bidTemplate"  render={this.props.readonly?readonlybidTemplate:bidTemplate} />
            </TreeList>
            </div>
        );
    }
}




export default Tbl;
