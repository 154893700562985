import React from 'react';
import Constants from "../../core/Constants";

export default function PalletColorTempalte(options) {
    const key = options.column.dataField
    const bidaction = options.data[key];
    const palletinginprogress = options.data.palletinginprogress;
    const palletcompleted = options.data.palletcompleted;


    let style = {};
    if(palletcompleted){
        style = {backgroundColor:"green",color:"white",padding:5}
    }
    if(palletinginprogress){
        style = {backgroundColor:"red",color:"white",padding:5}
    }


    if (!bidaction) {
        return <span className="name"></span>;
    }

    if(key=="pallet" && !bidaction.toString().includes("Total")){
        return <span className="name" style={style} >{bidaction}</span>;
    }

    return (
        <React.Fragment>
            <span style={{
                background: "grey",
                color: "white",
                fontWeight:"bold",
                padding:3
            }} >{bidaction}</span>
        </React.Fragment>
    );
}
