import React from 'react';
import DataGrid, {
    Column,
    HeaderFilter,
    Editing,
    Grouping,
    GroupPanel,
    GroupItem,
    Export,
    TotalItem,
    Summary,
    SearchPanel,
    Scrolling,
    ColumnChooser,
    Pager
} from 'devextreme-react/data-grid'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import $ from 'jquery';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import {Utilites} from "../../../core/Utilites";
class SimpleTbl2 extends React.Component {

    constructor(props) {
        super(props);
        this.onExporting = this.onExporting.bind(this);
    }
    onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
            });
        });
        e.cancel = true;
    }
    onEditorPreparing(e) {

        if(this.props.readonly){
            return;
        }
        if (e.dataField === 'bid' ) {
            let rowid = e.row.data.id;
            e.row.data.total = e.editorOptions.value;
        }
    }

    customizeTotal(data) {
        return `Total: ${parseFloat(data.value).toFixed(2)}`;
    }
    customizeQty(data) {
        return `Quantity: ${data.value}`;
    }
    customizeBid(data) {
        return `Average Price: ${parseFloat(data.value).toFixed(2)}`;
    }
    customizeDigit(data) {
        if(data.value=="" && data.value!="0"){
            return "$";
        }
        return `$ ${parseFloat(data.value).toFixed(2)}`;
    }

    recursiveitemfunction(data){

        let items = []
        if(data.collapsedItems){
            items = data.collapsedItems
        }else if(data.items){
            items = data.items
        }else
            if(items.length>0){
            for (let i = 0; i < items.length; i++) {
                if(items[i].collapsedItems){
                    items = items.concat(items[i].collapsedItems)
                }else if(items[i].items){
                    items =items.concat(items[i].items)
                }
            }
        }else if( data && data[0] && !data[0].id){
                for (let i = 0; i < data.length; i++) {
                    if(data[i].collapsedItems){
                        items =items.concat(data[i].collapsedItems)
                    }else if(data[i].items){
                        items = items.concat(data[i].items)
                    }
                }
            }
        if(items.length==0 || items[0] && items[0].id){
            return items;
        }
        return this.recursiveitemfunction(items);



    }
    render() {
        const{showcell} = this.props
        return (
            <DataGrid
                dataSource={this.props.data}
                allowColumnReordering={true}
                columnAutoWidth={true}
                showBorders={true}
                onCellPrepared={(e)=> {
                    if (e.rowType === "group") {
                        try{
                            let itemsparent = []
                            if(e.data.collapsedItems){
                                itemsparent=e.data.collapsedItems;
                            }
                            if(e.data.items){
                                itemsparent=e.data.items;
                            }
                            if(itemsparent.length){
                                let empty = itemsparent.find(x=>x.bid.toString().trim()=="")
                                let full = itemsparent.find(x=>x.bid.toString().trim()!="")

                                if(empty && full){
                                    e.cellElement.style.backgroundColor = "#ff9a9a";

                                    //if(!e.row.isExpanded){
                                        this.props.showCompleted(full.pallet)
                                   // }

                                }else if(!empty && full){
                                    e.cellElement.style.backgroundColor = "green";

                                }
                            }
                        }catch (e) {

                        }

                }
                }}
                onSaving={(e)=>{
                     // the correct one
                    e.changes.map(x=>{
                        this.props.preparemassiveUpdate(x.key.id,[],false,x.data.bid,false,false)
                    })
                    this.props.massiveUpdate(false,false);
                }}
                ref={this.props.dataGridRef}
                onExporting={this.onExporting}
                height={800}
                saveAllChanges={(e)=>{

                }}
                onCellClick={(e)=>{
                    if(this.props.readonly){
                        return;
                    }
                    let groupvalue = 0;
                    let globalevent =e ;
                    let globalthis = this
                   // debugger
                     if(e.rowType=="group" && e.column.caption=="Bid"){
                         Utilites.setTimeout(()=>{
                             document.getElementById("focusmus").focus();
                         },1000)
                         confirmAlert({
                             message: 'Please enter your group bid for :'+e.key,
                             childrenElement: () => <div ><input onKeyPress={ (eventx) => {
                                 if(eventx.key === 'Enter'){
                                     let items = globalthis.recursiveitemfunction(e.data);
                                     for (let i = 0; i < items.length; i++) {
                                         let rowid = items[i].id;
                                         if(!rowid){

                                             // recursive solution plz
                                             let subitems = []
                                             if(items[i].items){
                                                 subitems = items[i].items;
                                             }else if(items[i].collapsedItems){
                                                 subitems = items[i].collapsedItems;
                                             }
                                             for (let ii = 0; ii < subitems.length; ii++) {
                                                 rowid = subitems[ii].id;
                                                 let parent = false;
                                                 let bidslist = []
                                                 this.props.preparemassiveUpdate(rowid,bidslist,parent,groupvalue,false)
                                             }
                                         }else{
                                             let parent = false;
                                             let bidslist = []
                                             this.props.preparemassiveUpdate(rowid,bidslist,parent,groupvalue,false)
                                         }

                                     }
                                     this.props.massiveUpdate(false,true);

                                     globalevent.component.refresh(true);
                                     //globalthis.dataGridRef.current.instance.refresh()

                                     $(".cancelButton").click()
                                 }
                             }} type={"number"} id={"focusmus"} style={{marginTop:10}} onChange={(e)=>{
                                 groupvalue = e.target.value;
                             }
                             }/></div>,
                             buttons: [
                                 {
                                     label: 'Save',
                                     onClick: () => {
                                         let items = globalthis.recursiveitemfunction(e.data);
                                             for (let i = 0; i < items.length; i++) {
                                                 let rowid = items[i].id;
                                                 if(!rowid){

                                                     // recursive solution plz
                                                     let subitems = []
                                                     if(items[i].items){
                                                         subitems = items[i].items;
                                                     }else if(items[i].collapsedItems){
                                                         subitems = items[i].collapsedItems;
                                                     }
                                                     for (let ii = 0; ii < subitems.length; ii++) {
                                                         rowid = subitems[ii].id;
                                                         let parent = false;
                                                         let bidslist = []
                                                         this.props.preparemassiveUpdate(rowid,bidslist,parent,groupvalue,false)
                                                     }
                                                 }else{
                                                     let parent = false;
                                                     let bidslist = []
                                                     this.props.preparemassiveUpdate(rowid,bidslist,parent,groupvalue,false)
                                                 }

                                             }

                                             this.props.massiveUpdate(false,true);

                                         globalevent.component.refresh(true);
                                        // globalthis.dataGridRef.current.instance.refresh()
                                     }
                                 },
                                 {
                                     label: 'Cancel',
                                     className:"cancelButton",
                                     onClick: () => {

                                     }
                                 }
                             ]
                         });


                    }
                }
                }
                wordWrapEnabled={true}
                readonly={this.props.readonly}
                onEditorPreparing={this.onEditorPreparing.bind(this)}
            >
                <Scrolling rowRenderingMode='virtual'/>
                <Pager
                    showPageSizeSelector={true}
                    showNavigationButtons={true}
                />



                <ColumnChooser enabled={true} />
               <GroupPanel visible={true} />
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <Grouping autoExpandAll={false} />
                <Editing
                    mode="batch"
                    allowUpdating={true}
                    allowAdding={false}
                    allowDeleting={false} />
                <HeaderFilter visible={true} />



                {showcell}
                <Column dataField="qty" caption="Qty" allowEditing={false} fixed={true} fixedPosition={"right"} />
                <Column customizeText={this.customizeDigit} cssClass={"colormin"} allowEditing={!this.props.readonly} dataField="bid" caption="Bid" fixed={true} fixedPosition={"right"}  />
                <Column customizeText={this.customizeDigit} dataField="total" caption="Total" allowEditing={false} fixed={true} fixedPosition={"right"} />
                <Export enabled={true} allowExportSelectedData={false} />
                <Summary>
                    <GroupItem
                        column="qty"
                        summaryType="sum"
                        displayFormat="{0}"
                        alignByColumn={true}
                    />
                    <GroupItem
                        column="bid"
                        customizeText={this.customizeDigit}
                        summaryType="avg"
                        displayFormat="{0}"
                        cssClass={"colormin"}
                        showInGroupFooter={false}
                        alignByColumn={true} />
                    <GroupItem
                        column="total"
                        customizeText={this.customizeDigit}
                        summaryType="sum"
                        displayFormat="{0}"
                        showInGroupFooter={false}
                        alignByColumn={true} />

                    <TotalItem
                        column="qty"
                        label="qty"
                        customizeText={this.customizeQty}
                        summaryType="sum" />

                    <TotalItem
                        column="bid"
                        label="bid"
                        customizeText={this.customizeBid}
                        summaryType="avg" />

                    <TotalItem
                        column="total"
                        label="total"
                        customizeText={this.customizeTotal}
                        summaryType="sum" />

                </Summary>

            </DataGrid>
        );
    }
}




export default SimpleTbl2;
