import md5 from "md5";
import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import FormRow from "../../components/Row/FormRow";
import { AvForm } from 'availity-reactstrap-validation';
import Select from "react-select";

import ImageUpload from "../../components/CustomUpload/ImageUpload";
import {Utilites} from "../../core/Utilites";



export default class UserAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);

        this.state = {
            btnloading: false,

            full_name: "",
            fullpicture:"",
            email:"",
            phone:"",
            password: "",
            username:"",
            comments:"",
            first_name:"",
            last_name:"",
            birthday:"",
            picture:"",
            gender:"",
            status:"",
            newpassword:"",
            _id : ""
        };
        this.engine = new RequestEngine();
    }


    componentDidMount() {
        const edit = this.props.location.data

        if (edit){
            this.setState({
                ...edit,
            });
        }
    }





    handleValidSubmit = () => {

        let data = {...this.state}
        const edit = this.props.location.data


        if(!edit && !Utilites.validatePassword(data.password)){
            Utilites.showErrorMessage("Please Write a Strong Password");
            return;
        }


        if(edit && data.newpassword && !Utilites.validatePassword(data.newpassword)){
            Utilites.showErrorMessage("Please Write a Strong Password");
            return;
        }else{
            data.password = md5(data.password)
        }

        if(edit && data.newpassword){
            data.newpassword = md5(data.newpassword)
        }


            this.engine.saveUserManager(data,(response) => {
                if(response.status === 200 && response.data.success){
                    this.props.history.push('/admin/manager');
                }else{
                  Utilites.showErrorMessage(response.data.message)
             }
                this.setState({
                    btnloading: false
                })
            });

    };


    onChangePic(e) {
        this.setState({picture:e.target.files[0]})
    }
    renderSubscribedinfo(){

        return (<>
            <FormRow label="First name" name="first_name" data={this.state.first_name} changeInput={this.changeInput.bind(this)} />
            <FormRow label="Last name" name="last_name" data={this.state.last_name} changeInput={this.changeInput.bind(this)} />
            <FormRow label="Birthday" type="date" required={false} name="birthday" data={this.state.birthday} changeInput={this.changeInput.bind(this)} />
            <Row>
                <Label sm="2">Picture ID</Label>
                <Col sm="7">
                    <FormGroup >
                        <ImageUpload placeholder={this.state.fullpicture} labelupload="Select Picture" onChange={(e) => this.onChangePic(e)}/>
                    </FormGroup>
                </Col>

            </Row>

        </>)
    }

    renderManagerinfo(){

        return (<>
            <FormRow label="Full name" name="full_name" data={this.state.full_name} changeInput={this.changeInput.bind(this)} />
            <FormRow label="Email" name="email"  type={"email"} data={this.state.email} changeInput={this.changeInput.bind(this)} />

        </>)
    }
    render() {
        // taking all the states
        const {
            full_name,
            password,
            username,
            comments,
            phone,
            first_name,
            last_name,
            birthday,
            picture,
            gender,
            status,
            newpassword,
            btnloading,_id} = this.state;
        const {normaluser} = this.props
        const edit = this.props.location.data
        return (
            <>
                <div className="content english">
                    <Row>
                        <Col md="12">
                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{!_id && "Add"} {_id && "Edit"}</CardTitle>
                                    </CardHeader>
                                    <CardBody>

                                        {!normaluser && this.renderManagerinfo()}


                                        <FormRow label="Phone"  required={false}  name="phone" data={phone} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="username"  name="username" data={username} changeInput={this.changeInput.bind(this)} />
                                        {!edit && <FormRow label="Password " type="password" name="password" data={password} changeInput={this.changeInput.bind(this)} />}

                                        {!normaluser && (edit && <FormRow label="New Password " required={false} type="password" name="newpassword" data={newpassword} changeInput={this.changeInput.bind(this)} />)}

                                        <FormRow required={false} label="Comment" type="textarea" name="comments" data={comments} changeInput={this.changeInput.bind(this)} />

                                        <div>
                                            <h5>Password Policy</h5>
                                            <ul>

                                                <li>1- Minimum of 8 digits</li>
                                                <li> 2- At least 1 number</li>
                                                <li>3- At least 1 lower character</li>
                                                <li>4- At least 1 uppercase character</li>
                                                <li>5- At least 1 special character</li>
                                            </ul>
                                        </div>

                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={btnloading}>Save</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


