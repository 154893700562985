import md5 from "md5";
import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import FormRow from "../../components/Row/FormRow";
import { AvForm } from 'availity-reactstrap-validation';
import Select from "react-select";

import ImageUpload from "../../components/CustomUpload/ImageUpload";
import {Utilites} from "../../core/Utilites";
import SelectRow from "../../components/Select/SelectRow";


export default class RowUserAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);

        this.state = {
            btnloading: false,
            managertypelist:[],
            full_name: "",
            email:"",
            ismanager:true,
            bidcategorylist:[],
            phone:"",
            bidcategory:[],
            company:"",
            username:"",
            newpassword:"",
            password:"",
            password2:"",
            dob:"",
            country:"",
            spokenlang:[],
            _id : ""
        };
        this.engine = new RequestEngine();
    }



    componentDidMount() {
        const edit = this.props.location.data
        if (edit){
            this.setState({
                ...edit,
               // bidcategory:Array.isArray(edit.bidcategory)?edit.bidcategory:[]
            });
        }


        this.prepareData();


    }


    async prepareData(){
        let userid = localStorage.getItem("userid");
        const managertype = localStorage.getItem("managertype");
        if(!managertype || managertype=="normaluser"){
           this.setState({
               ismanager :false
           })
        }
        let responseuni = await this.engine.getItemlistDirect("bidcategory");
        if(responseuni){
            let bidcategorylist = []
            responseuni.data.data.data.map(p =>{
                    bidcategorylist.push( { value: p.id, label: p.name})
                }
            )
            this.setState({
                bidcategorylist,
                internalload:false
            });
        }
            try{
                let result = await this.engine.getItemlistDirect("myuser","/info/"+userid)
                if(result){
                    this.setState({
                        ...result.data.data,
                        password:"",
                        edit:true

                    })
                }
            }catch (e) {
                if(e.response.status==401){
                    this.props.history.push('/auth/login');
                }
            }

    }




    handleValidSubmit = () => {

        const {password2,password,newpassword} = this.state;
        const myuserid = this.props.myuserid
        const edit = this.props.location.data
        let data = {...this.state}

        if(!edit && password!=password2){
          Utilites.showErrorMessage("Please Confirm Password");
          return;
        }else if(edit &&  newpassword!=password2){
            Utilites.showErrorMessage("Please Confirm Password");
            return;
        }

        let validatepassword = true;
        if(myuserid && password.length==0 ){
            validatepassword = false
        }

        if(!edit && !Utilites.validatePassword(password)){
            Utilites.showErrorMessage("Please Write a Strong Password");
            return;
        }
        data.password = md5(password)

        if(edit && newpassword && !Utilites.validatePassword(newpassword)){
            Utilites.showErrorMessage("Please Write a Strong Password");
            return;
        }

        if(edit && newpassword){
            data.newpassword = md5(newpassword)
        }

        // please write a strong password

        let bidcategory = []
        this.state.bidcategory.map(i=>{
            if(i.value){
                bidcategory.push(i.value)
            }else{
                bidcategory.push(i)
            }
        })

        data.bidcategory=bidcategory;


        try{
            this.engine.saveUserMobile(data,(response) => {
                if(response.status === 200){
                    if(response.data.success){
                        if(!edit){
                            this.props.history.push('/admin/user');
                        }else{
                            Utilites.showSucessMessage("Profile Updated!")
                        }

                    }else{
                        Utilites.showErrorMessage(response.data.message)
                    }

                }
                this.setState({
                    btnloading: false
                })
            });
        }catch (e) {
            alert("registration error")
        }









    };




    render() {
        // taking all the states
        const {
            full_name,
            username,
            bidcategory,
            ismanager,
            password2,company,
            phone,
            email,
            newpassword,
            bidcategorylist,
            btnloading,_id,password} = this.state;
        const edit = this.props.location.data
        return (
            <>
                <div className="content english">
                    <Row>
                        <Col md="12">
                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{!_id && "Add"} {_id && "Edit"}</CardTitle>
                                    </CardHeader>
                                    <CardBody>

                                        <FormRow label="username"  name="username" data={username} changeInput={this.changeInput.bind(this)} />


                                        {ismanager && <SelectRow label="bids category" isMulti={true}  name="bidcategory"   data={bidcategorylist} defaultValue={bidcategory && bidcategory.map((item) => {
                                            return bidcategorylist.find(v => v.value === item.value || v.value === item.id || v.value === item)
                                        })} changeInput={data =>
                                            this.setState({ bidcategory: data })
                                        }  />}
                                        <FormRow label="full name" required={false} name="full_name" data={full_name} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="phone"  name="phone" data={phone} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="email"  type={"email"}  name="email" data={email} changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="company"  required={false} name="company" data={company} changeInput={this.changeInput.bind(this)} />

                                    </CardBody>

                                    <CardHeader>
                                        <CardTitle tag="h4">Change Password</CardTitle>

                                    </CardHeader>
                                    <CardBody>
                                        {!edit ? <FormRow label="Password " type="password" name="password" data={password} changeInput={this.changeInput.bind(this)} />:<FormRow label="New Password " required={false} type="password" name="newpassword" data={newpassword} changeInput={this.changeInput.bind(this)} />}
                                        <FormRow label="confirm password"  required={false} type="password" name="password2" data={password2} changeInput={this.changeInput.bind(this)} />

                                        <div>
                                            <h5>Password Policy</h5>
                                            <ul>

                                                <li>1- Minimum of 8 digits</li>
                                                <li> 2- At least 1 number</li>
                                                <li>3- At least 1 lower character</li>
                                                <li>4- At least 1 uppercase character</li>
                                                <li>5- At least 1 special character</li>
                                            </ul>
                                        </div>
                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={btnloading}>Save</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


