import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

export default class EditSettings extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            email: "",
            loading : false
        };
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.callPage()
        this.checkPermission();
    }


    checkPermission(){
        const managertype = localStorage.getItem("managertype");
        if(!managertype || managertype=="normaluser"){
            this.props.history.push('/admin/notfound');
        }
    }

    callPage() {
        try{
            this.engine.getSettingsinfo((response) => {
                console.log(response.status);
                if(response.status === 200){

                    this.setState({
                        isLoading: false,
                        email: response.data.data.email
                    });
                }
            });
        }catch (e) {
            this.setState({
                isLoading: false
            });
        }


    }





    typeClick = () => {

        this.setState({
            loading: true
        })

        this.engine.saveSettings(this.state,(response) => {
            console.log(response.status);

            this.setState({
                loading: false
            })

        });

    };

    render() {
        // taking all the states
        const {email,loading,isLoading}= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">
                    <Row>
                        <Col md="12">
                            <Form className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Settings</CardTitle>
                                    </CardHeader>
                                    <CardBody>

                                        <Row>
                                            <Label sm="2">Email Send From</Label>
                                            <Col sm="7">
                                                <FormGroup >
                                                    <Input
                                                        name="email"
                                                        type="text"
                                                        value = {email}
                                                        onChange={e =>
                                                            this.changeInput(e, "email")
                                                        }
                                                    />

                                                </FormGroup>
                                            </Col>
                                            <Col className="label-on-right" tag="label" sm="3">
                                                <code>required</code>
                                            </Col>
                                        </Row>







                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" onClick={() =>
                                            this.typeClick()
                                        } loading={loading}>Save</ButtonLoader>

                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


