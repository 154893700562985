import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';

import nationalityList from "../../components/Select/Nationality"
import Constants from "../../core/Constants";
import {Utilites} from "../../core/Utilites";
import * as XLSX from "xlsx";

export default class UploadAuction extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            _id:"",
            fullpicture:"",
            picture:"",
            loading : false,
        };
        this.pathname = "useruploaditemauction";
        this.engine = new RequestEngine();


        this.idFileBase64 = "";
    }

    componentDidMount() {

        const edit = this.props.location.data
        if (edit){
            this.setState({
                ...edit
            });
        }
      //  this.checkPermission();
    }


    checkPermission(){
        const managertype = localStorage.getItem("managertype");
        if(!managertype || managertype=="normaluser"){
            this.props.history.push('/admin/notfound');
        }
    }



    handleValidSubmit = async () => {
        this.setState({
            loading: true
        })

        const { picture}= this.state;
        const formData = new FormData();
        formData.append('picture',picture)
        const response = await this.engine.saveItemData(this.pathname,formData)

        if(response && response.status === 200){
            Utilites.showSucessMessage("Uploaded !")

            this.props.history.push('/admin/summary');
        }
        this.setState({
            loading: false
        })

    };


    onChangePic(e) {
        e.preventDefault();
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        this.setState({picture:e.target.files[0]})
        reader.onload =  (e)=> {
            var data = e.target.result;
            let readedData = XLSX.read(data, {type: 'binary'});
            this.setState({
                readedData:readedData
            })
        };
        reader.readAsBinaryString(f)
    }

    onChangePicar(e) {
        Utilites.getFileBase64(e.target.files[0], (result) => {
            this.idFileBase64 = result;
        });
    }
    render() {
        // taking all the states
        const {loading,isLoading}= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4"> information</CardTitle>

                                        <a href={"/auctionexample.xlsx"}>Download XLSX example</a>
                                        <br/>
                                        <span style={{color:"red"}}>if the file is big it make take up to 15mb</span>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Label sm="2">XLSX   </Label>
                                            <Col sm="5">
                                                <FormGroup >
                                                    <ImageUpload placeholder={this.state.fullpicture} labelupload="Select File" isfile={true} onChange={(e) => this.onChangePic(e)}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>Save</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


