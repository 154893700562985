import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col,
    DropdownItem,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import ReactTable from "react-table";
import Constants from "../../core/Constants";
import {Utilites} from "../../core/Utilites";

class AdminUserBidsTotal extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
            bidsinformation:{},
            rand:0,
        };
        this.pathname = "auctionbyuser";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.callPage()
    }








    async handleDelete(valueid) {
        this.props.loadingAction(true)
        const result = await this.engine.deleteItem(this.pathname,valueid)
        if(result){
            this.handlePage();
        }
        this.props.loadingAction(false)

    }




    handleActions(index,item) {

        return (<Button onClick={()=>{
        this.props.history.push("/admin/usersummary/"+item.id+"/"+item.userid)
        }
        } >View Details</Button>)
    }

    async callPage() {


        const category = this.props.match.params.category



        const response = await  this.engine.getItem(this.pathname,"/list/"+category);

        if(response && response.status === 200){
            this.setState({ // cname,cemail,cposition,cdesc,category,nationality,address,website
                    isLoading: false,
                bidsinformation:response.data.data.bidsinformation,
                    dataSource: response.data.data.items.map((item, key) => {
                        return {
                            id: key,
                            data:item,
                            user: item.user,
                            price:parseFloat(item.price).toFixed(2),
                            created:this.renderDate(item.createdAt),
                            actions: (
                                // we've added some custom button actions
                                this.handleActions(key,item)
                            )

                        };
                    })
                }
            );
        }

        this.props.loadingAction(false);
    }
    handlePage() {
        this.props.loadingAction(true);
        this.callPage()
    }



    render() {
        const {dataSource,isLoading,bidsinformation} = this.state;

        let columns = [
            {
                Header: "User",
                accessor: "user"
            },
            {
                Header: "Total Bids",
                accessor: "price"
            },
            {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false
            }
        ]



        let bigtitle =[]
        bigtitle["20"]="Austel";
        bigtitle["25"]="Chicago";
        bigtitle["40"]="Brooksville";
        bigtitle["50"]="Phoenix";
        bigtitle["55"]="Stow";
        bigtitle["60"]="Olympia";
        bigtitle["65"]="Dallas";
        bigtitle["70"]="Durham";

        let location = bidsinformation.wh;
        if(bigtitle[location]){
            location = bigtitle[location];
        }
        return (
            <>

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" >Bids By User</CardTitle>
                                    {!isLoading && <CardTitle tag="h5" >Bids Information : {location} / {bidsinformation.palletdept} / {bidsinformation.category}  </CardTitle>}

                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={dataSource}
                                        defaultFilterMethod={this.filterCaseInsensitive}
                                        filterable
                                        loading={isLoading}
                                        columns={columns}
                                        defaultPageSize={10}
                                        showPaginationTop
                                        showPaginationBottom={false}
                                        /*
                                          You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                        */
                                        className="-striped -highlight primary-pagination"
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}


const mapStateToProps = state => ({
    bids:state.bids,
});

const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(mapStateToProps, mapDispatchToProps)(AdminUserBidsTotal);
