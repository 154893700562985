import React from "react";
import FullCalendar from "@fullcalendar/react";
import daygridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useState } from "react";
import { v4 as uuid } from "uuid";

const EventItem = ({ info }) => {
    const { event } = info;
    return (
        <div>
            <p>{event.title}</p>
        </div>
    );
};

export const MyCalendar = ({handleSelect,events,viewonly}) => {

    return (
        <div>
            <FullCalendar
                editable={!viewonly?true:false}
                selectable={!viewonly?true:false}
                height={600}
                events={events}
                select={!viewonly?handleSelect:null}
                eventClick={!viewonly?handleSelect:null}
                headerToolbar={{
                    start: "today prev next",
                    center: "title",
                    end: "dayGridMonth dayGridWeek dayGridDay"
                }}
                eventContent={(info) => <EventItem info={info} />}
                plugins={[daygridPlugin, interactionPlugin]}
                views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}
            />
        </div>
    );
};
