import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col, Button,
} from "reactstrap";
import CustomStore from 'devextreme/data/custom_store'

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';

import { LoadPanel } from 'devextreme-react/load-panel';
// Our demo infrastructure requires us to use 'file-saver-es'.
//  We recommend that you use the official 'file-saver' package in your applications.
import { exportPivotGrid } from 'devextreme/excel_exporter';
import {DataGrid, Column, Editing, Selection} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import PivotGrid, {StateStoring,FieldPanel,Export
} from 'devextreme-react/pivot-grid';

import { ProgressBar } from 'devextreme-react/progress-bar';
import CoreEngine from "../../core/CoreEngine";
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import bidsAction from "../../core/redux/actions/bidsAction";
import RequestEngine from "../../core/RequestEngine";
import {Utilites} from "../../core/Utilites";
import 'react-table-hoc-fixed-columns/lib/styles.css'
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import SimpleTbl2 from "./tblsimple/SimpleTbl2";

function statusFormat(value) {
    return `Loading: ${parseFloat(value * 100).toFixed(0)}%`;
}



class SummaryList extends CoreEngine {
    constructor() {
        super();
        this.dataGridRef = React.createRef();
        this.state = {
            data: [],
            userinfo:{},
            forceloading:false,
            tempbidssave:[],
            progress:5,
            btnloading: false,
            showadvancedloader:true,
            isLoading: true,
            expanded: {},
            bidsinformation:{},
            cols:[],
            rawdata:[],
            total:0,
            status:"",
            page:0,
            count:0,
            groupvalue:0,
            incompletedpallet:[],
            userid:"",
            dataSource:{},
            // devexpress dataSource
            dataSourceDev:{},
            store:{},
            rawstore:[],
            advanced:false,
            popupTitle: '',
            drillDownDatafSource: null,
            popupVisible: false,
            devexpressdata:[],
            maxValue : 10,
            seconds: 10,
            buttonText: 'Start progress',
            inProgress: false,
        };
        this.pathname = "summarydetaildetails";
        this.engine = new RequestEngine();
        this.filterserial = ""
        this.onHiding = this.onHiding.bind(this);
        this.onShown = this.onShown.bind(this);
        this.getDataGridInstance = this.getDataGridInstance.bind(this);
        this.onExporting = this.onExporting.bind(this);
        this.selectionChanged = this.selectionChanged.bind(this);
        this.intervalId = null;
        this.provalue = 5;

        this.incompletedpallet = [];
    }




    onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportPivotGrid({
            component: e.component,
            worksheet,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Sales.xlsx');
            });
        });
        e.cancel = true;


    }



    selectionChanged(data) {
        this.setState({
            selectedItemKeys: data.selectedRowKeys
        });
    }

    onHiding() {
        this.setState({
            popupVisible: false
        });
    }
    onShown() {
        this.dataGrid.updateDimensions();
    }
    componentDidMount() {
       this.callDevPageSimple(0);
        this.setState({
            userid:this.props.adminview?this.props.match.params.userid:localStorage.getItem("userid")
        })


    }





    updateGroupBid(groupid,itemid,price) {
        let params = {
            groupidlist:groupid,
            price:price,
            item:itemid,
            userid:this.state.userid
        }
        this.engine.directpostItem("auction","/group/save",params);
    }



    async preparemassiveUpdate(id,bidslist,parent,value,directsave=true,reloaddata=true) {


        let {tempbidssave,devexpressdata} = this.state

        // to do fix if already found
        let found = tempbidssave.find(x=>x.id==id);
        if(found){
            found.id=id;
                found.bidslist=bidslist;
                found.value=value;
                found.parent=parent;
        }else{
            tempbidssave.push({
                id:id,
                bidslist:bidslist,
                value:value,
                parent:parent
            })
        }

        if(!directsave){

           let foundindex =  devexpressdata.findIndex(x=>x.id==id)
            if(foundindex!=-1){
                devexpressdata[foundindex].bid = value
                devexpressdata[foundindex].total = value
            }

        }
        this.setState({
            tempbidssave,
            devexpressdata
        },()=>{
            if(directsave){
                this.massiveUpdate(false,reloaddata)
            }
        })

    }
    async massiveUpdate(sendemail = false,reloaddata=true) {

        const {tempbidssave,advanced,devexpressdata} = this.state
        this.setState({
            btnloading : true,
            forceloading:true,
        })
        let params = {
            data:tempbidssave,
            userid:this.state.userid,
            sendemail:sendemail
        }

       /* tempbidssave.map(x=>{
            let found = devexpressdata.find(z=>z.id==x.id);

            if(found){
                found.bid = x.value;
                found.total = x.value*found.qty;
            }
        })*/

        await this.engine.directpostItem("auction","/devmassivesave",params);
        this.setState({
            btnloading : false,
            tempbidssave:[],

        },()=>{
            if(reloaddata){
                if(advanced){
                    this.callPage(0)
                }else{
                    this.callDevPageSimple(0);
                }
            }

            Utilites.setTimeout(()=>{
                this.setState({
                    forceloading:false
                })
                this.setState({
                    incompletedpallet : this.incompletedpallet
                })
            },2000)


        })


        Utilites.setTimeout(()=>{
            this.setState({
                incompletedpallet : this.incompletedpallet
            })
        },2000)
    }

    async grabtototal() {
        const category = this.props.match.params.category
        const response = await  this.engine.getItem("gettotal","/"+category);
        if(response && response.status === 200){
            this.setState({
                    total:response.data.data.total,
                    status:response.data.data.status,
                }
            );
        }
        Utilites.setTimeout(()=>{
            //this.grabtototal()
        },4000)
    }
    async callPage(page,filtered = []) {
        const category = this.props.match.params.category
        this.setState({
            isLoading:true
        })

        const userid = this.props.adminview?this.props.match.params.userid:localStorage.getItem("userid");

        const response = await  this.engine.postItem("pivotsummarydetaildetails","/"+category+"/"+userid+"/"+page,filtered);
        if(response && response.status === 200){
            this.updateCoreState(response.data.data.data);
        }

        this.grabtototal();
        this.props.loadingAction(false);
    }

    updateCoreState(exdata){
        const dataSource = new PivotGridDataSource({
            fields: [{
                caption: 'Model',
                // width: 120,
                dataField: 'model',
                area: 'row',
                // sortBySummaryField: 'Total'
            },
                {
                    dataField: 'category',
                    caption: 'category',
                    // dataType: 'date',
                    area: 'column'
                }

                ,{
                    caption: 'Quantity',
                    dataField: 'qty',
                    dataType: 'number',
                    summaryType: 'sum',
                    area: 'data'
                },
                {
                    caption: 'Average Bid',
                    dataField: 'bid',
                    dataType: 'number',
                    summaryType: 'avg',
                    area: 'data'
                },
                {
                    caption: 'Total Bid',
                    dataField: 'bid',
                    dataType: 'number',
                    summaryType: 'sum',
                    area: 'data'
                },
            ],
            store: exdata
        });



        this.setState({
            dataSource,
            rawdata :exdata

        })
    }

    updateDevCoreState(exdata){

        const dataSourceDev = new PivotGridDataSource({
            store: exdata
        });

        this.setState({
            dataSourceDev:dataSourceDev,

        })
    }




    async callDevPageSimple(page,filtered = []) {
        const category = this.props.match.params.category
        this.props.loadingAction(true);

        let config = {
            responseType: 'stream',
            onDownloadProgress: progressEvent => {
                const progressupdate = this.state.progress+10;
                if(progressupdate>100){
                    return;
                }
                this.setState({
                    progress:progressupdate
                })
                this.provalue =  this.provalue+10
            }
        }

        let userid = this.props.adminview?this.props.match.params.userid:localStorage.getItem("userid");


        const response = await  this.engine.postItemWithCustomConfig("remotedevexpresssummarydetaildetails","/"+category+"/"+userid+"/"+page,filtered,config);

        if(response && response.status === 200){
            let result = response.data.replace(/{"done":false}/g, "");
             result = result.replace("\"done\":false,", "");
            let test = result.split("data: {");
            let updatedresult = []
            if(test.length==2){
                test = test[1].replace("\"done\":false,", "");
                test = test.replace("\"done\":true,", "");
                test = "{"+test;
                updatedresult = JSON.parse(test);
            }else{
                updatedresult = JSON.parse(result);
            }

            if(!this.state.bidsinformation.palletdept){
                this.setState({
                    bidsinformation:updatedresult.data.bidsinformation,
                })
            }

            debugger
            this.setState({
                    isLoading: false,
                    devexpressdata:updatedresult.data.devexpressdata,
                cols:updatedresult.data.cols,
                count:updatedresult.data.count,
                userinfo:updatedresult.data.userinfo,
                page:page,
                },()=>{

                Utilites.setTimeout(()=>{
                    this.setState({
                        incompletedpallet : this.incompletedpallet
                    })
                },1000)
                }
            );
            Utilites.setTimeout(()=>{
                this.setState({
                    showadvancedloader : false
                })
            },600)

        }

        if(!this.props.adminview){
          //  this.callPage(0)
        }

        this.grabtototal();
        this.props.loadingAction(false);
    }
    showCompleted(value) {
        if(value && !this.incompletedpallet.includes(value)){
            this.incompletedpallet.push(value)
        }
        console.log("showInCompleted")
    }

     handleChange(e,index,itemid,parentitem) {
        const value = e.target.value;
         let {data} = this.state;
         let itemfound = data.find(x=>x.taskid==parentitem.taskid);
         if(itemfound){
             itemfound.bidslist[index].bid = value
             this.setState({
                 data:data
             })
         }
         // update state bid with item_id
        // this.updateOneBid(itemid,value)
    }

    updateOneBid(itemid,price) {
        let params = {
            item:itemid,
            price:price,
            userid:this.state.userid
        }
        this.engine.directpostItem("auction","/save",params);
    }


    filterRawText(filter, row) {
        // Pivoted rows won't contain the column.
        //  If that's the case, we set the to true (allowing us to only filter on the current column)
        let rowValue = row[filter.id];
        if (!rowValue) {
            return true;
        }
        // debugger
        const index = row._index


        rowValue =  this.state.data[index][filter.id]
        let bidslist = this.state.data[index].bidslist;
        if(!rowValue){
            return;
        }
        const filterValue = filter.value;
        //const filterValue = filter.value.filterValue || "";
        //const filterType = filter.value.filterType;
        this.filterserial = filterValue;

        //  debugger
        let bidserialfound = bidslist.find(x=>x.serial.includes(filterValue.toLowerCase()));
        const filterType = "contains";
        if(bidserialfound && bidserialfound.serial){
            return true;
        }
        switch (filterType) {
            case "contains":
                return rowValue.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
            case "starts-with":
                return rowValue.startsWith(filterValue);
            case "ends-with":
                return rowValue.endsWith(filterValue);
            case "matches":
                return rowValue === filterValue;
            case "greater-than":
                return rowValue > filterValue;
            case "less-than":
                return rowValue < filterValue;
            default:
                return true;
        }
    }



    async handleViewBenExport() {
        const category = this.props.match.params.category
        this.props.loadingAction(true);
        await  this.engine.exportCSV("alldata","/export/"+category);
        this.props.loadingAction(false);
    }

    renderSimpleTbl2(){
        let { devexpressdata,isLoading,forceloading,cols } = this.state;

        let showcell = []


            for(let i=0;i<cols.length;i++){
                if(cols[i].slug.includes("quantity") || cols[i].name.includes("quantity") || cols[i].name.includes("bid") || cols[i].name.includes("price") || cols[i].name.includes("total") || cols[i].name.includes("customer")){
                    continue;
                }
                const cleanName = this.autoCleanValue(cols[i].name,true)

                if(cols[i].slug=="pallet"){
                    showcell.push(<Column dataField={cols[i].slug}  caption={cleanName} allowEditing={false} groupIndex={0} />)
                }else{
                    showcell.push(<Column dataField={cols[i].slug} caption={cleanName} allowEditing={false} />)
                }

             }



        return (<>
                <CardBody>

                    {forceloading && <LoadPanel  visible={forceloading}/>}
                    <div style={{opacity:forceloading?0:1}}>
                        <SimpleTbl2 showCompleted={(e)=>this.showCompleted(e)} dataGridRef={this.dataGridRef} onexport={this.handleViewBenExport.bind(this)} showcell={showcell} isLoading={isLoading}  isdescriptiongroup={false} ismodelgroup={false} massiveUpdate={this.massiveUpdate.bind(this)} preparemassiveUpdate={this.preparemassiveUpdate.bind(this)} readonly={this.props.readonly} data={devexpressdata} defaultexpand={{}} />
                    </div>
                 </CardBody>
            </>
        )
    }

    getDataGridInstance(ref) {
        if(ref && ref.instance){
            this.dataGrid = ref.instance;
        }

    }

    render() {
        const { incompletedpallet,userinfo,store,popupTitle ,isLoading,popupVisible,bidsinformation,advanced} = this.state;



        let bigtitle =[]
        bigtitle["20"]="Austel";
        bigtitle["25"]="Chicago";
        bigtitle["40"]="Brooksville";
        bigtitle["50"]="Phoenix";
        bigtitle["55"]="Stow";
        bigtitle["60"]="Olympia";
        bigtitle["65"]="Dallas";
        bigtitle["70"]="Durham";

        let location = bidsinformation.wh;
        if(bigtitle[location]){
            location = bigtitle[location];
        }


       // var lastvalue = this.state.maxValue - this.state.seconds
        if(this.state.showadvancedloader){
            return <div className="content">
                <ProgressBar
                    id="progress-bar-status"
                    className={this.provalue == 100 ? 'complete' : '' }
                    width="100%"
                    min={0}
                    max={100}
                    statusFormat={statusFormat}
                    value={this.provalue}
                />

            </div>
        }

        if(advanced &&  isLoading || (!bidsinformation.palletdept && isLoading) ){
            return <LoadPanel  visible={true}/>
        }



           //



        return (
            <>

                <div className="content">

                    <Popup
                        visible={popupVisible}
                        width={600}
                        height={400}
                        title={popupTitle}
                        onHiding={this.onHiding}
                        onShown={this.onShown}
                    >


                        {!this.props.readonly &&<div style={{position: "relative",
                             width: 300}}>
                            <input placeholder={"group bid "} readOnly={this.props.readonly} onChange={(e)=> {

                                const value = e.target.value;
                                this.setState({
                                    groupvalue :value
                                })
                            }} />
                            {!this.props.readonly &&<Button onClick={()=>{
// get the main item id , and send group

                                let storex = this.state.rawstore;

                                let groupids = [];

                                let rowdata = this.state.rawdata;
                                storex.map(i=>{
                                    groupids.push(i._id);
                                    rowdata.find(ii=>ii._id==i._id).bid = this.state.groupvalue;
                                })
                                this.updateCoreState(rowdata);
                                this.updateGroupBid(groupids,groupids[0],this.state.groupvalue);
                                this.setState({
                                    popupVisible:false
                                })
                            }}
                            >Save</Button>}
                        </div>}

                        <DataGrid
                            width={560}
                            height={300}
                           dataSource={store}
                            ref={this.getDataGridInstance}
                            onSelectionChanged={this.selectionChanged}
                        >
                            <Selection mode="single" />
                            {!this.props.readonly &&<Editing
                                mode="cell"
                                allowUpdating={true} />}
                            <Column dataField="model"  allowEditing={false} />
                            <Column dataField="serial" allowEditing={false} />
                            <Column dataField="bid" dataType="number"  >
                                <input type={"text"} />
                            </Column>
                            <Column dataField="pallet" allowEditing={false} />
                        </DataGrid>



                    </Popup>
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" >Details </CardTitle>
                                    {bidsinformation.palletdept && <CardTitle tag="h5" > Information : {location} / {bidsinformation.palletdept} / {bidsinformation.category}  </CardTitle>}
                                    {userinfo.username && <>Username : {userinfo.username}<br/></>}
                                    {userinfo.email && <>email : {userinfo.email}<br/></>}
                                    {userinfo.phone && <>phone : {userinfo.phone}<br/></>}
                                    {userinfo.company && <>company : {userinfo.company}</>}

                                    {incompletedpallet.length>0 && <div style={{color:"#ff9a9a",float: "right",fontWeight: "bold"}}>Incomplete Pallet : {incompletedpallet.join(",")}</div>}
                                    {advanced && <Button color="primary" className="pull-right" onClick={() =>
                                        this.state.dataSource && this.state.dataSource.state({})
                                    }>
                                        Reset State
                                    </Button>}

                                </CardHeader>
                                {!advanced ? this.renderSimpleTbl2():
                                <CardBody>

                                    <PivotGrid
                                        id="pivotgrid"
                                        onExporting={this.onExporting}
                                        dataSource={this.state.dataSource}
                                        allowSortingBySummary={false}
                                        allowFiltering={false}
                                        showBorders={false}
                                        showColumnTotals={false}
                                        showColumnGrandTotals={false}
                                        showRowTotals={true}
                                        showTotalsPrior={false}
                                        allowExpandAll={true}
                                        showRowGrandTotals={true}
                                        onCellClick={(e)=>{
                                            if (e.area == 'data') {
                                                var pivotGridDataSource = e.component.getDataSource();

                                                let alldata = this.state.rawdata;
                                                let popupTitle = [];

                                                for(let i=0;i<e.cell.rowPath.length;i++){
                                                    let rowPathName = e.cell.rowPath[i];
                                                    var rowFields= e.rowFields[i].dataField
                                                    if(rowPathName && rowPathName.length>0){

                                                        popupTitle.push(rowPathName);
                                                    }
                                                    alldata = alldata.filter(i=>i[rowFields]==rowPathName)
                                                }



                                                const store = new CustomStore({
                                                    key: '_id',
                                                  // loadMode: 'raw',
                                                    load: (loadOptions) => {
                                                        return  alldata;
                                                    },
                                                    insert: (values) => {

                                                        // ...
                                                    },
                                                    update: (key, values) => {
                                                        let newbid = values.bid;
                                                        this.updateOneBid(key,newbid)
                                                        let rowdata = this.state.rawdata;
                                                        rowdata.find(i=>i._id==key).bid = newbid
                                                        this.updateCoreState(rowdata);
                                                    },
                                                    remove: (key) => {

                                                        // ...
                                                    },
                                                });

                                                this.setState({
                                                    popupTitle: popupTitle.join(" / "),
                                                    drillDownDataSource: pivotGridDataSource.createDrillDownDataSource(e.cell),
                                                    store:store,
                                                    rawstore:alldata,
                                                    popupVisible: true
                                                });
                                            }
                                        }}
                                    >

                                        <Export enabled={true} />
                                        <StateStoring
                                            enabled={true}
                                            type="localStorage"
                                            storageKey="dx-widget-gallery-pivotgrid-storing"
                                        />
                                        <FieldPanel
                                            showColumnFields={false}
                                        showDataField= {false}
                                        showFilterFields={false}
                                        showRowFields={true}
                                        allowFieldDragging ={true}

                                            showDataFields={false}
                                            visible={true}
                                        />
                                    </PivotGrid>
                                </CardBody>}
                                <CardHeader>
                                    {!this.props.readonly && <ButtonLoader color="primary" loading={this.state.btnloading} className="pull-right" onClick={() =>{
                                        let currentGrid = this.dataGridRef;
                                        if(currentGrid.current.instance.hasEditData()){
                                            currentGrid.current.instance.saveEditData().then(() => {
                                                if(!currentGrid.current.instance.hasEditData()) {
                                                    // Saved successfully
                                                } else {
                                                    // Saving failed
                                                }
                                            });
                                        }
                                        //this.massiveUpdate(false,true);
                                    }
                                    }>
                                        Save Bids
                                    </ButtonLoader>}


                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );

    }
}



const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload)),
    bidsAction: (payload) => dispatch(bidsAction(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SummaryList);
