import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';

import nationalityList from "../../components/Select/Nationality"
import Constants from "../../core/Constants";

export default class ItemAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            wh:"",
            location:"",
            pallet :"",
            palletdescription:"",
            palletdept:"",
            screensize:"",
            laptopcore:"",
            wireless:"",
            ethernet:"",
            hddcaddy:"",
            battery:"",
            category:"",
            adaptor:"",
            serial:"",
            make :"",
            model:"",
            processortype :"",
            processorspeed:"",
            extracol:{},
            ram:"",
            harddrive :"",
            coa :"",
            numbrofcors:"",
            opticaldrive:"",
            formfactor:"",
            grade :"",
            mfgrserial:"",
            _id:"",
            loading : false
        };
        this.pathname = "item";
        this.engine = new RequestEngine();
    }

    componentDidMount() {


        const edit = this.props.location.data
        if (edit){
            this.setState({
                ...edit,
            });
        }
        if (edit && edit.translate){
            this.setState({
                ...edit.translations[0],
            });
        }
        this.checkPermission();
    }


    checkPermission(){
        const managertype = localStorage.getItem("managertype");
        if(!managertype || managertype=="normaluser"){
            this.props.history.push('/admin/notfound');
        }
    }



    handleValidSubmit = async () => {


        this.setState({
            loading: true
        })



        const response = await this.engine.saveItem(this.pathname,this.state)

        if(response && response.status === 200){
            this.props.history.push('/admin/'+this.pathname);
        }
        this.setState({
            loading: false
        })

    };


    render() {
        // taking all the states
        const {serial,isLoading,  pallet ,
            location,palletdescription,palletdept,category,extracol}= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        let output = [];
        for (const key in extracol) {
            if(extracol[key] && extracol[key].toString().length>0){
                output.push(<FormRow label={this.autoCleanValue(key,true)} readonly={true} name={key} data={extracol[key]}    />)
            }

        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{"Information"}</CardTitle>
                                    </CardHeader>
                                    <CardBody>

                                        <FormRow readonly={true} label="location"  name="location" data={location}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow readonly={true} label="pallet" type={"number"} name="pallet" data={pallet}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow readonly={true} label="palletdescription" name="palletdescription" data={palletdescription}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow readonly={true} label="palletdept" name="palletdept" data={palletdept}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow readonly={true} label="category" name="category" data={category}   changeInput={this.changeInput.bind(this)} />
                                        <FormRow label="serial" readonly={true} name="serial" data={serial}   changeInput={this.changeInput.bind(this)} />

                                        {output}
                                    </CardBody>


                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


