import React from "react";

import Login from "views/login/Login.jsx";
import UserList from "./views/user/UserList";
import RowUserAddUpdate from "./views/user/RowUserAddUpdate";

import EditSettings from "./views/settings/EditSettings";
import UserAddUpdate from "./views/user/UserAddUpdate";
import ItemAddUpdate from "./views/items/ItemAddUpdate";
import ItemList from "./views/items/ItemList";
import CategoryList from "./views/categroy/CategoryList";
import CategoryAddUpdate from "./views/categroy/CategoryAddUpdate";
import ContactList from "./views/contact/ContactList";
import ItemUpload from "./views/items/ItemUpload";
import SummaryList from "./views/contact/SummaryList";
import AdminSummary from "./views/admin/AdminSummary";
import AdminUserBidsTotal from "./views/admin/AdminUserBidsTotal";
import ItemGroupList from "./views/itemgroup/ItemGroupList";
import ItemGroupAddUpdate from "./views/itemgroup/ItemGroupAddUpdate";
import NotFound from "./views/NotFound";
import ReadOnlySummary from "./views/userarchive/ReadOnlySummary";
import TutorialList from "./views/tutorial/TutorialList";
import UploadAuction from "./views/uploadauction/UploadAuction";
import SheetList from "./views/adminuploadconfig/SheetList";
import ColAddUpdate from "./views/adminuploadconfig/ColAddUpdate";
import SheetAddUpdate from "./views/adminuploadconfig/SheetAddUpdate";
import UploadManager from "./views/adminuploadconfig/UploadManager";
import ColMap from "./views/adminuploadconfig/ColMap";
import ColMapAddUpdate from "./views/adminuploadconfig/ColMapAddUpdate";
import AdminUserUploader from "./views/adminuploadconfig/AdminUserUploader";
import AuctionBatchList from "./views/auctionbatch/AuctionBatchList";
import DeleteAuctionItemGroup from "./views/user/DeleteAuctionItemGroup";
import StockCodeList from "./views/stockcode/StockCodeList";
import StockCodeListAddUpdate from "./views/stockcode/StockCodeListAddUpdate";
import StockCodeChildList from "./views/stockcode/StockCodeChildList";
import StockCodeChildAddUpdate from "./views/stockcode/StockCodeChildAddUpdate";
import BidcategoryList from "./views/bidcategory/BidcategoryList";
import BidcategoryAddUpdate from "./views/bidcategory/BidcategoryAddUpdate";
import Reset from "./views/reset/Reset";
import FuturebidList from "./views/futurebids/FuturebidList";
import ItemsByItemGroupList from "./views/itemgroup/ItemsByItemGroupList";
import ViewBidDetailsByItemGroup from "./views/itemgroup/ViewBidDetailsByItemGroup";





const ViewOnlyFuturebidList = (props) => {
    return (
        <FuturebidList
            viewonly={true}
            {...props}
        />
    );
}

const ManagerList = (props) => {
    return (
        <UserList
            manager={true}
            {...props}
        />
    );
}
const ViewSummaryList = (props) => {
    return (
        <SummaryList
            readonly={true}
            {...props}
        />
    );
}

const AdminViewSummaryList = (props) => {
    return (
        <SummaryList
            readonly={true}
            adminview={true}
            {...props}
        />
    );
}




let myuserid = localStorage.getItem("userid");




const MyRowUserAddUpdate = (props) => {
    return (
        <RowUserAddUpdate
            myuserid={myuserid}
            {...props}
        />
    );
}

const routes = [
    {
        path: "/summary",
        name: "Summary",
        menu: true,
        allow : ["normaluser"],
        icon: "nc-icon nc-layout-11",
        component: ContactList,
        layout: "/admin"
    },
    {
        path: "/adminsummary",
        name: "admin summary",
        menu: true,
        allow : ["admin"],
        icon: "nc-icon nc-layout-11",
        component: AdminSummary,
        layout: "/admin"
    },

    {
        path: "/itemgroup",
        name: "Auction",
        menu: true,
        allow : ["admin"],
        icon: "nc-icon nc-layout-11",
        component: ItemGroupList,
        layout: "/admin"
    },



    {
        path: "/bidcategory",
        name: "Bid Category",
        menu: true,
        allow : ["admin"],
        icon: "nc-icon nc-layout-11",
        component: BidcategoryList,
        layout: "/admin"
    },

    {
        path: "/future",
        name: "Future Bid",
        menu: true,
        allow : ["admin"],
        icon: "nc-icon nc-layout-11",
        component: FuturebidList,
        layout: "/admin"
    },

    {
        path: "/notfound",
        name: "notfound",
     //   menu: true,
       // allow : ["admin"],
        icon: "nc-icon nc-layout-11",
        component: NotFound,
        layout: "/admin"
    },





    {
        path: "/users",
        name: "Users",
        classItem:"menuItem",
        allow: ["admin"],
        icon: "nc-icon nc-badge",
        menu: true,
        layout: "/admin",
        collapse: true,
        state: "pagesCollapse4",
        views: [
            {
                path: "/user",
                name: "Customers",
                menu: true,
                allow : ["admin"],
                //icon: "nc-icon nc-badge",
                component: UserList,
                layout: "/admin"
            },
            {
                path: "/manager",
                name: "Manager",
                menu: true,
                allow : ["admin"],
                //icon: "nc-icon nc-badge",
                component: ManagerList,
                layout: "/admin"
            },

        ]
    },






    {
        path: "/toremove",
        name: "To Remove",
        classItem:"menuItem",
        allow: ["admin"],
        icon: "nc-icon nc-badge",
        menu: true,
        layout: "/admin",
        collapse: true,
        state: "pagesCollapmmse46",
        views: [
            {
                path: "/auction",
                name: "Bid",
                menu: true,
                allow : ["admin"],
               // icon: "nc-icon nc-layout-11",
                component: CategoryList,
                layout: "/admin"
            },
            {
                path: "/itemk",
                name: "item",
                menu: true,
                allow : ["admin"],
               // icon: "nc-icon nc-layout-11",
                component: ItemList,
                layout: "/admin"
            },
            {
                path: "/colmap",
                name: "column map",
                menu: true,
                allow : ["admin"],
                //icon: "nc-icon nc-badge",
                component: ColMap,
                layout: "/admin"
            },
            {
                path: "/sheet",
                name: "sheet",
                menu: true,
                allow : ["admin"],
                //icon: "nc-icon nc-badge",
                component: SheetList,
                layout: "/admin"
            },
            {
                path: "/sheetmanager",
                name: "sheet manager",
                menu: true,
                allow : ["admin"],
                //icon: "nc-icon nc-badge",
                component: UploadManager,
                layout: "/admin"
            },

            {
                path: "/userauctionuploader",
                name: "User Auction Uploader",
                menu: true,
                allow : ["admin"],
                //icon: "nc-icon nc-badge",
                component: AdminUserUploader,
                layout: "/admin"
            },

            {
                path: "/auctionbatch",
                name: "User Auction Batch",
                menu: true,
                allow : ["admin"],
                //icon: "nc-icon nc-badge",
                component: AuctionBatchList,
                layout: "/admin"
            },
            {
                path: "/stockcode",
                name: "Stock Code",
                menu: true,
                allow : ["admin"],
                //icon: "nc-icon nc-badge",
                component: StockCodeList,
                layout: "/admin"
            },
            {
                path: "/stockcodechild",
                name: "Stock Code Child",
                menu: true,
                allow : ["admin"],
                //icon: "nc-icon nc-badge",
                component: StockCodeChildList,
                layout: "/admin"
            },




        ]
    },









    {
        path: "/sheet/add",
        name: "add sheet",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: SheetAddUpdate,
        layout: "/admin"
    },

    {
        path: "/itemgroupviewitems/:id",
        name: "add sheet",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: ItemsByItemGroupList,
        layout: "/admin"
    },


    {
        path: "/itemgroupviewbids/:id",
        name: "view bids by itemgroup",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: ViewBidDetailsByItemGroup,
        layout: "/admin"
    },


    {
        path: "/sheet/edit/:id",
        name: "edit sheet",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: SheetAddUpdate,
        layout: "/admin"
    },

    {
        path: "/bidcategory/add",
        name: "add bidcategory",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: BidcategoryAddUpdate,
        layout: "/admin"
    },
    {
        path: "/bidcategory/edit/:id",
        name: "edit bidcategory",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: BidcategoryAddUpdate,
        layout: "/admin"
    },


    {
        path: "/stockcodechild/add/:stockcodeid",
        name: "add stockcodechild",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: StockCodeChildAddUpdate,
        layout: "/admin"
    },
    {
        path: "/stockcodechild/edit/:id",
        name: "edit stockcodechild",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: StockCodeChildAddUpdate,
        layout: "/admin"
    },



    {
        path: "/col/add",
        name: "add col",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: ColAddUpdate,
        layout: "/admin"
    },
    {
        path: "/col/edit/:id",
        name: "edit col",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: ColAddUpdate,
        layout: "/admin"
    },



    {
        path: "/stockcode/add",
        name: "add StockCodeListAddUpdate",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: StockCodeListAddUpdate,
        layout: "/admin"
    },
    {
        path: "/stockcode/edit/:id",
        name: "edit StockCodeListAddUpdate",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: StockCodeListAddUpdate,
        layout: "/admin"
    },



    {
        path: "/colmap/add",
        name: "add colmap",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: ColMapAddUpdate,
        layout: "/admin"
    },
    {
        path: "/colmap/edit/:id",
        name: "edit colmap",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: ColMapAddUpdate,
        layout: "/admin"
    },

    {
        path: "/thread/edit/:id",
        name: "edit category",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: CategoryAddUpdate,
        layout: "/admin"
    },

    {
        path: "/itemgroup/edit/:id",
        name: "edit itemgroup",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: ItemGroupAddUpdate,
        layout: "/admin"
    },



    {
        path: "/thread/add",
        name: "add category",
        menu: false,
        // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: CategoryAddUpdate,
        layout: "/admin"
    },

    {
        path: "/summarybids/:category",
        name: "view summary",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: AdminUserBidsTotal,
        layout: "/admin"
    },

    {
        path: "/summary/:category",
        name: "view summary",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: SummaryList,
        layout: "/admin"
    },
    {
        path: "/viewsummary/:category",
        name: "view summary readonly",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: ViewSummaryList,
        layout: "/admin"
    },
    {
        path: "/usersummary/:category/:userid",
        name: "view summary",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: AdminViewSummaryList,
        layout: "/admin"
    },
    {
        path: "/item/edit/:id",
        name: "edit item",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: ItemAddUpdate,
        layout: "/admin"
    },
    {
        path: "/itemgroup/upload",
        name: "edit item",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: ItemUpload,
        layout: "/admin"
    },

    {
        path: "/item/add",
        name: "add item",
        menu: false,
        icon: "nc-icon nc-settings-gear-65",
        component: ItemAddUpdate,
        layout: "/admin"
    },




    {
        path: "/login",
        name: "Login",
        mini: "L",
        menu: false,
        component: Login,
        layout: "/auth"
    },

    {
        path: "/resetpassword/:id",
        name: "Reset",
        mini: "L",
        menu: false,
        component: Reset,
        layout: "/auth"
    },



    {
        path: "/manager",
        name: "Manager",
       // menu: true,
       // allow : ["admin"],
        icon: "nc-icon nc-badge",
        component: ManagerList,
        layout: "/admin"
    },
    {
        path: "/user",
        name: "User",
      //  menu: true,
      //  allow : ["admin"],
        icon: "nc-icon nc-badge",
        component: UserList,
        layout: "/admin"
    },


    {
        path: "/user/deleteuseritemgroup/:id",
        name: "User delete auction from item group",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: DeleteAuctionItemGroup,
        layout: "/admin"
    },
    {
        path: "/user/edit/:id",
        name: "User edit",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: RowUserAddUpdate,
        layout: "/admin"
    },
    {
        path: "/myuser/edit",
        name: "Edit Profile",
        allow : ["normaluser"],
        menu: false,
        icon: "nc-icon nc-single-02",
        component: MyRowUserAddUpdate,
        layout: "/admin"
    },
    {
        path: "/myuser/upload",
        name: "Upload Prices",
       // allow : ["normaluser"],
        menu: false,
        icon: "nc-icon nc-single-02",
        component: UploadAuction,
        layout: "/admin"
    },
    {
        path: "/myuser/archive",
        name: "Archive",
        allow : ["normaluser"],
        menu: false,
        icon: "nc-icon nc-single-02",
        component: ReadOnlySummary,
        layout: "/admin"
    },
    {
        path: "/user/add",
        name: "add user",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: RowUserAddUpdate,
        layout: "/admin"
    },
    {
        path: "/tutorial",
        name: "tutorial",
        allow : ["normaluser"],
        menu: false,
        icon: "nc-icon nc-single-02",
        component: TutorialList,
        layout: "/admin"
    },

    {
        path: "/futurebids",
        name: "Future Bids",
        allow : ["normaluser"],
        menu: false,
        icon: "nc-icon nc-single-02",
        component: ViewOnlyFuturebidList,
        layout: "/admin"
    },


    {
        path: "/manager/edit/:id",
        name: "Edit manager",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: UserAddUpdate,
        layout: "/admin"
    },
    {
        path: "/manager/add",
        name: "add manager",
        menu: false,
        icon: "nc-icon nc-single-02",
        component: UserAddUpdate,
        layout: "/admin"
    },








    {
        path: "/settings",
        name: "Settings",
        menu: false,
       // allow : ["admin"],
        icon: "nc-icon nc-settings-gear-65",
        component: EditSettings,
        layout: "/admin"
    },









];



export default routes;
