import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";
import * as XLSX from 'xlsx';
import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';

import Constants from "../../core/Constants";
import {Utilites} from "../../core/Utilites";
import {Button} from "bootstrap/js/src";

export default class AdminUserUploader extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            from:"",
            title:"",
            to:"",
            _id:"",
            sheet:"",
            fullpicture:"",
            picture:"",
            loading : false,
            userlist:[],
            user:"",
            itemgrouplist:[],
            itemgroup:"",
            takebidfromsummary:"no",

        };
        this.pathname = "useruploaditemauctionadmin";
        this.engine = new RequestEngine();

        this.idFileBase64 = "";
        this.location=[];
        this.palletdepts=[];
        this.category=[];
        this.admincol=[]
        this.colmap = [];
        this.allcols=[];
    }

    componentDidMount() {

        const edit = this.props.location.data
        if (edit){
            this.setState({
                ...edit
            });
        }
        //  this.checkPermission();
        this.prepareData()
    }

    async prepareData(){
        let responseuni = await this.engine.getItemlistDirect("user");
        if(responseuni){
            const data = responseuni.data.data;
            let userlist = []
            data.map(p =>{
                    userlist.push( { value: p._id, label: p.username})
                }
            )
            this.setState({
                userlist
            });
        }

        responseuni = await this.engine.getItemlistDirect("itemgroupsadmin");
        if(responseuni){
            const data = responseuni.data.data;
            let itemgrouplist = []
            data.map(p =>{
                itemgrouplist.push( { value: p._id, label: p.title})
                }
            )
            this.setState({
                itemgrouplist
            });
        }





        this.setState({
            isLoading:false
        })





    }


    checkPermission(){
        const managertype = localStorage.getItem("managertype");
        if(!managertype || managertype=="normaluser"){
            this.props.history.push('/admin/notfound');
        }
    }



    handleValidSubmit = async () => {

        const { picture,user,itemgroup,takebidfromsummary}= this.state;

        if(!itemgroup){
            Utilites.showErrorMessage("please select item group");
            return;
        }
        if(!user){
            Utilites.showErrorMessage("please select user ");
            return;
        }
        this.setState({
            loading: true
        })

        const formData = new FormData();
        formData.append('picture',picture)
        formData.append('user',user)
        formData.append('itemgroup',itemgroup)
        formData.append('takebidfromsummary',takebidfromsummary)

        const response = await this.engine.saveItemData(this.pathname,formData)

        if(response && response.status === 200){
            Utilites.showSucessMessage("Uploaded !")
            this.props.history.push('/admin/auctionbatch');
        }
        this.setState({
            loading: false
        })

    };




    onChangePic(e) {
        e.preventDefault();
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        this.setState({picture:e.target.files[0]})
        reader.onload =  (e)=> {
            var data = e.target.result;
            let readedData = XLSX.read(data, {type: 'binary'});
            this.setState({
                readedData:readedData
            })
        };
        reader.readAsBinaryString(f)
    }



    renderstep1() {
        // taking all the states
        const {loading,isLoading,user,userlist,itemgrouplist,itemgroup,takebidfromsummary}= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <Card>
                    <CardHeader>
                        <CardTitle tag="h4"> information</CardTitle>

                        <a href={"/auctionexample.xlsx"}>Download XLSX example</a>
                        <br/>
                        <span style={{color:"red"}}>if the file is big it make take up to 15mb</span>
                    </CardHeader>
                    <CardBody>

                        <SelectRow  label={"Please User"} name={user}  defaultValue={user}  data={userlist}  changeInput={(e)=>{
                            this.setState({
                                user:e.value
                            })
                        }}  />

                        <SelectRow  label={"Please Item group"} name={itemgroup}  defaultValue={itemgroup}  data={itemgrouplist}  changeInput={(e)=>{
                            this.setState({
                                itemgroup:e.value
                            })
                        }}  />

                        <SelectRow  label={"Take Bid From Summary Item group"} name={takebidfromsummary}  defaultValue={takebidfromsummary}  data={this.createSelectValue(["yes","no"])}  changeInput={(e)=>{
                            this.setState({
                                takebidfromsummary:e.value
                            })
                        }}  />



                      <Row>
                            <Label sm="2">XLSX   </Label>
                            <Col sm="5">
                                <FormGroup >
                                    <ImageUpload placeholder={this.state.fullpicture} labelupload="Select File" isfile={true} onChange={(e) => this.onChangePic(e)}/>
                                </FormGroup>
                            </Col>
                        </Row>


                    </CardBody>


                    <CardFooter className="text-center">
                        <ButtonLoader  color="primary" loading={loading}>Submit</ButtonLoader>

                    </CardFooter>
                </Card>
            </>
        );
    }




    render() {
        // taking all the states
        const {step,loading,isLoading,user,userlist}= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                {this.renderstep1()}
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


