import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col,
    DropdownItem,
    UncontrolledDropdown,
    PaginationItem, PaginationLink, Pagination,
    DropdownMenu,
    DropdownToggle, Modal
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import {MyCalendar} from "./MyCalendar";
import FormRow from "../../components/Row/FormRow";
import {AvForm} from "availity-reactstrap-validation";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";
import {Utilites} from "../../core/Utilites";
import md5 from "md5";

class FuturebidList extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
            modalClassic:false,
            btnloading:false,
            editmode:false,
            _id:"",
            name:"",
            date:"",
            time:"",
            events:[],
        };
        this.pathname = "bidfuture";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.callPage();
    }


    async callPage() {
        try{
            this.setState({
                isLoading : true,
            })
            const response = await  this.engine.getItemlistDirect(this.pathname);
            if(response && response.status === 200){
                this.setState({
                    events: response.data.data.map((item, key) => {
                            return {
                                id: item.id,
                                title:item.name+(item.time?" / "+item.time:""),
                                time: item.time ,
                                name: item.name ,
                                start:item.from,
                                color:Utilites.getRandomColor(),
                                end:item.to,
                                datex:item.from.toString(),
                            };
                        })
                    }
                );
            }
        }catch (e) {

            console.log("e",e)
        }

        this.setState({
            isLoading: false,
        })
        this.props.loadingAction(false);
    }


    handleSelect = (info) => {
        const { start, end,event } = info;
        if(event){
            this.setState({
                modalClassic:true,
                name:event.extendedProps.name,
                time:event.extendedProps.time,
                from:event.extendedProps.datex,
                to:event.extendedProps.datex,
                _id:event._def.publicId,
                editmode:true,
            })
        }else{
            this.setState({
                modalClassic:true,
                editmode:false,
                date:start,
            })
        }

    };

    toggleModalMapClassic = () => {
        this.setState({
            modalClassic: !this.state.modalClassic
        });
    };

    handleValidSubmit = async () => {


        this.setState({
            btnloading: true
        })

        const response = await this.engine.saveItem(this.pathname,this.state)

        if(response && response.status === 200){
            Utilites.showSucessMessage("Event Added!")
            this.callPage();
        }
        this.setState({
            btnloading: false,
            modalClassic:false,
            title:"",
            time: "" ,
            _id:""
        })

    };

    async handleDelete(valueid) {
        this.props.loadingAction(true)
        const result = await this.engine.deleteItem(this.pathname,this.state._id)
        this.setState({
            btnloading: false,
            modalClassic:false,
            title:"",
            time: "" ,
            _id:""
        })
        if(result){
            this.callPage();
        }
        this.props.loadingAction(false)

    }
    renderMapCode() {
        const {modalClassic,name,time,btnloading,editmode} = this.state;

        return (<>
            <Modal
                isOpen={modalClassic}
                toggle={this.toggleModalMapClassic}
            >
                <div className="modal-header justify-content-center english">
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={this.toggleModalMapClassic}
                    >
                        <i className="nc-icon nc-simple-remove" />
                    </button>
                    <h4 className="title title-up">Add Bid Information </h4>
                </div>
                <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">

                <div className="modal-body">

                       <FormRow label="Bid Name"  name="name" data={name} changeInput={this.changeInput.bind(this)} />
                       <FormRow label="Bid Time"  name="time" type={"time"} data={time} changeInput={this.changeInput.bind(this)} />

                </div>
                <div className="modal-footer">
                    <div className="center-side">
                        {editmode && <Button onClick={() => this.handleDelete()} color="danger" loading={btnloading}>Delete</Button>}
                        <ButtonLoader color="primary" loading={btnloading}>Save</ButtonLoader>
                        <Button
                            className="btn-link"
                            color="default"
                            data-dismiss="modal"
                            type="button"
                            onClick={this.toggleModalMapClassic}
                        >
                            Hide
                        </Button>
                    </div>
                </div>
            </AvForm>
            </Modal>
        </>)
    }


    render() {
        const {events,isLoading} = this.state

        const {viewonly} = this.props;
        if(isLoading){
            return this.renderProgress()
        }
        return (
            <>
                <div className="content">
                    {this.renderMapCode()}
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" className="pull-left">Future Bids</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <MyCalendar viewonly={true} events={events} handleSelect={(e)=>this.handleSelect(e)} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(null, mapDispatchToProps)(FuturebidList);
