import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Table,
    Row,
    Col
} from "reactstrap";
import RequestEngine from "../../core/RequestEngine"
import CoreEngine from "../../core/CoreEngine";

import ImageUpload from "../../components/CustomUpload/ImageUpload";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader";

import FormRow from "../../components/Row/FormRow"
import SelectRow from "../../components/Select/SelectRow"
import { AvForm } from 'availity-reactstrap-validation';

import nationalityList from "../../components/Select/Nationality"
import Constants from "../../core/Constants";
import Checkboxrow from "../../components/Checkbox/Checkboxrow";
import {Utilites} from "../../core/Utilites";

export default class CategoryAddUpdate extends CoreEngine {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            description:"",
            title:"",
            _id:"",
            loading : false,
            approved:"yes",
            picture:"",
            fullpicture:""

        };
        this.pathname = "thread";
        this.idFileBase64 = "";
        this.engine = new RequestEngine();
    }

    componentDidMount() {


        const edit = this.props.location.data
        if (edit){
            this.setState({
                ...edit,
                approved:edit.status==1?"yes":"no",
                fullpicture:edit.picture.length>1 ? Constants.serverlink+"upload/"+edit.picture:""
            });
        }

    }


    onChangePic(e) {
        Utilites.getFileBase64(e.target.files[0], (result) => {
            this.idFileBase64 = result;
        });
        this.setState({picture:e.target.files[0]})
    }



    handleValidSubmit = async () => {


        this.setState({
            loading: true
        })


        let data = {...this.state}


        data.picturebase64 = this.idFileBase64;

        const response = await this.engine.saveItem(this.pathname,data)

        if(response && response.status === 200){
            this.props.history.push('/admin/'+this.pathname);
        }
        this.setState({
            loading: false
        })

    };


    render() {
        // taking all the states
        const {loading,isLoading,  description,approved,title,_id }= this.state;

        if (isLoading) {
            return this.renderProgress()
        }
        return (
            <>
                <div className="content english">

                    <h4></h4>
                    <Row>
                        <Col md="12">

                            <AvForm  onValidSubmit={() =>this.handleValidSubmit()} className="form-horizontal" id="TypeValidation">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">{"Information"}</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <FormRow label="title" name="title" data={title}   changeInput={this.changeInput.bind(this)} />
                                        <Row>
                                            <Label sm="2">Image</Label>
                                            <Col sm="5">
                                                <FormGroup >
                                                    <ImageUpload placeholder={this.state.fullpicture} labelupload="Select Image" onChange={(e) => this.onChangePic(e)}/>
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <FormRow required={false} label="description" name="description" type={"textarea"} data={description} changeInput={this.changeInput.bind(this)}  />

                                        <SelectRow label="Approved"  name="approved"  defaultValue={approved}  data={this.createSelectValue(["yes","no"])} changeInput={this.changeInput.bind(this)} />


                                    </CardBody>

                                    <CardFooter className="text-center">
                                        <ButtonLoader color="primary" loading={loading}>Save</ButtonLoader>
                                    </CardFooter>
                                </Card>
                            </AvForm>

                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


