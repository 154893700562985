import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Row,
    Col,
    DropdownItem,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle, PaginationItem, PaginationLink, Pagination
} from "reactstrap";
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import ReactTable from "react-table";
import Constants from "../../core/Constants";
import {Utilites} from "../../core/Utilites";

class CategoryList extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
            page:0,
            count:0,
            pageSize:10,
            innerloading:true,
        };
        this.pathname = "adminlistauctionpaginated";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.checkPermission();
    }


    checkPermission(){
        const managertype = localStorage.getItem("managertype");
        if(!managertype || managertype=="normaluser"){
            this.props.history.push('/admin/notfound');
        }
    }



    async handleDelete(valueid) {
        this.props.loadingAction(true)
        const result = await this.engine.deleteItem(this.pathname,valueid)
        if(result){
            this.handlePage();
        }
        this.props.loadingAction(false)

    }


    handleEdit(index,dataid) {
        const data = this.state.dataSource[index].data?.item

        debugger
        this.props.history.push({
            pathname:'/admin/item/edit/'+dataid,
            data
        });

    }

    handleActions(index,item) {

        return (
                <Button  eventKey="2" onClick={() => this.handleEdit(index,item?.item._id)}>View</Button>
            )
    }
    async callPage(page,pageSize=10,filtered = [],sorted=[]) {

        try{

            this.setState({
                innerloading : true,

            })
            const response = await  this.engine.postItem(this.pathname,"/list/"+page+"/"+pageSize,filtered,sorted);

            if(response && response.status === 200){
                this.setState({
                        isLoading: false,
                    innerloading:false,
                        count:response.data.data.count,
                        page:page,
                    pageSize:pageSize,
                        dataSource: response.data.data.data.map((item, key) => {
                            return {
                                id: key,
                                data:item,
                                item: item.item && item.item.serial ,
                                user:item.user && item.user.username ,
                                price:item.price,
                                createdAt:this.renderDate(item.createdAt),
                                actions: (
                                    // we've added some custom button actions
                                    this.handleActions(key,item)
                                )

                            };
                        })
                    }
                );
            }
        }catch (e) {
            if(e.response.status==401){
                this.props.history.push('/auth/login');
            }
        }

        this.props.loadingAction(false);
    }
    handlePage() {
        this.props.loadingAction(true);
        this.callPage()
    }


    handleAdd() {
        this.props.history.push('/admin/'+this.pathname+'/add');
    }


    render() {
        const {dataSource,pageSize,innerloading,count} = this.state;

        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= count; i++) {
            pageNumbers.push(i);
        }
        const renderPageNumbers = pageNumbers.map((number, index) => {
            return (
                <PaginationItem   key={index}>
                    <PaginationLink
                        onClick={()=>this.callPage(number,pageSize)}
                    >
                        {number}
                    </PaginationLink>
                </PaginationItem>
            );
        })




        let columns = [
            {
                Header: "Item",
                accessor: "item"
            },
            {
                Header: "Bids",
                accessor: "price"
            },
            {
                Header: "User",
                accessor: "user"
            },


            {
                Header: "Created",
                accessor: "createdAt",

            },

            {
                Header: "Actions",
                accessor: "actions",
                sortable: false,
                filterable: false
            }
        ]


        return (
            <>

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" className="pull-left">Bid</CardTitle>
                                </CardHeader>
                                <Pagination
                                    className="pagination pagination-primary"
                                    listClassName="pagination-primary"
                                    style={{    overflow: "scroll"}}
                                >
                                    {renderPageNumbers}
                                </Pagination>
                                <CardBody>
                                    <ReactTable
                                        data={dataSource}
                                        defaultFilterMethod={this.filterCaseInsensitive}
                                        filterable
                                        manual
                                        loading={innerloading}
                                        columns={columns}
                                        defaultPageSize={10}
                                        showPaginationTop
                                        onFetchData={(state, instance) => {
                                            this.setState({
                                                innerloading:true
                                            })
                                            this.callPage(state.page, state.pageSize,state.filtered,state.sorted)
                                        }}
                                        showPaginationBottom={false}
                                        className="-striped -highlight primary-pagination"
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </>
        );
    }
}


const mapStateToProps = state => ({
    bids:state.bids,
});

const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);
