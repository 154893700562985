
import md5 from "md5";
import React from "react";

// reactstrap misc
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row
} from "reactstrap";

import blacklogo from "assets/img/logo.svg";
import RequestEngine from "../../core/RequestEngine";
import CoreEngine from "../../core/CoreEngine";
import { HollowDotsSpinner } from 'react-epic-spinners'
import {Utilites} from "../../core/Utilites";

class Login extends CoreEngine {

    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password:"",
            managertype : "",
            token : "",
            forgotpassword:false,
            loading : false,
        };
        this.engine = new RequestEngine();
    }

    componentDidMount() {
    document.body.classList.toggle("login-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

    async login(){

        let {username,password} = this.state


        try {
            this.setState({loading : true})

            password = md5(password)
            const result = await this.engine.login(username,password)


            if(result.status === 200 && result.data.success){

                const managertype = result.data.data.managertype.trim().toLowerCase();
                const token = result.data.data.token;

                localStorage.setItem('managertype', managertype);
                localStorage.setItem('userid', result.data.data.id);
                localStorage.setItem('token', token);
                localStorage.setItem('isloggedin', true);
                if(managertype=="normaluser"){
                    this.props.history.push('/admin/summary');
                }else{
                    this.props.history.push('/admin/adminsummary');
                }

            }else{
               Utilites.showErrorMessage(result.data.message)
                this.setState({password:""})
            }
        }catch (e) {
            alert("Wrong login info  ")
            this.setState({password:"",loading:false})
        }

        this.setState({loading : false})
    }


    async submitReset(){
        let {email} = this.state
        try {
            this.setState({loading : true})
            const result = await this.engine.reset(email)
            if(result.status === 200 && result.data.success){
                Utilites.showSucessMessage("Email sent!")
            }else{
                Utilites.showErrorMessage(result.data.message)
                this.setState({password:""})
            }
        }catch (e) {
            alert("Wrong login info  ")
            this.setState({password:"",loading:false})
        }

        this.setState({loading : false})
    }

    renderLostpassword() {
        const {email,loading,} = this.state
        return (
            <>
                <CardBody>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="nc-icon nc-single-02" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Email" value={email} onChange={e =>
                            this.changeInput(e, "email")
                        } type="text" />
                    </InputGroup>
                    <br />

                </CardBody>
                <CardFooter>
                    {loading && <div className="logo-img">
                        <HollowDotsSpinner color="#51BCDA"  animationDelay={50} />
                    </div>}
                    {!loading && <Button
                        block
                        className="btn-round mb-3"
                        style={{background:"#409DBA"}}
                        onClick={() => this.submitReset()}
                    >
                        Submit
                    </Button>}

                    <div>
                        <a  onClick={() => {

                            this.setState({
                                forgotpassword:false
                            })
                        }}>Back to Login</a>
                    </div>

                </CardFooter>
            </>
        );
    }
  render() {
        const {username,password,loading,forgotpassword} = this.state
    return (
      <div className="login-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form action="" className="form" method="">
                <Card className="card-login">
                  <CardHeader>
                    <CardHeader>
                      <div className="logo-img">
                          <img src={blacklogo} alt="react-logo" className="innerLogo" />
                      </div>

                    </CardHeader>
                  </CardHeader>
                    {!forgotpassword ?<>
                     <CardBody>
                         <InputGroup>
                             <InputGroupAddon addonType="prepend">
                                 <InputGroupText>
                                     <i className="nc-icon nc-single-02" />
                                 </InputGroupText>
                             </InputGroupAddon>
                             <Input placeholder="Username" value={username} onChange={e =>
                                 this.changeInput(e, "username")
                             } type="text" />
                         </InputGroup>
                         <InputGroup>
                             <InputGroupAddon addonType="prepend">
                                 <InputGroupText>
                                     <i className="nc-icon nc-key-25" />
                                 </InputGroupText>
                             </InputGroupAddon>
                             <Input
                                 placeholder="Password"
                                 type="password"
                                 autoComplete="off"
                                 value={password}
                                 onKeyPressCapture={event => {
                                     if(event.charCode == 13){
                                         this.login()
                                     }
                                 }}
                                 onChange={e =>
                                     this.changeInput(e, "password")
                                 }
                             />
                         </InputGroup>
                         <br />

                     </CardBody>
                     <CardFooter>
                         {loading && <div className="logo-img">
                             <HollowDotsSpinner color="#51BCDA"  animationDelay={50} />
                         </div>}
                         {!loading && <Button
                             block

                             className="btn-round mb-3"
                             style={{background:"#409DBA"}}
                             onClick={() => this.login()}
                         >
                             Login
                         </Button>}

                         <div>
                             <a  onClick={() => {

                                 this.setState({
                                     forgotpassword:true
                                 })
                             }}>Forgot password</a>
                         </div>

                     </CardFooter>
                 </>:this.renderLostpassword()}
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/david-marcu.jpg")})`
            //  backgroundImage: `url(${require("assets/img/bg/why-was-bitcoin-invented-1024x640.jpg")})`

          }}
        />
      </div>
    );
  }
}

export default Login;
