import React from "react";

// reactstrap misc
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button,
    Row as Rowx,
    Table,
    Col,
    DropdownItem,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle, PaginationItem, PaginationLink, Pagination
} from "reactstrap";
import { SortableContainer , SortableHandle , SortableElement , arrayMove } from  'react-sortable-hoc'
import CoreEngine from "../../core/CoreEngine";
import RequestEngine from "../../core/RequestEngine"
import {connect} from "react-redux";
import loadingAction from "../../core/redux/actions/loadingAction";
import ReactTable from "react-table";
import SelectRow from "../../components/Select/SelectRow";

//  Component which uses drag-n-drop activation when clicking inside the component
const  DragHandle = SortableHandle(({style}) => (
    <span style={{ ...style, ...{cursor : 'move' }}}> { '::::' } </span>)
)

// Universal component for turning a TableBody into a sortable container
const  TableBodySortable  =  SortableContainer(({children, displayRowCheckbox}) => (
    <tbody displayRowCheckbox={displayRowCheckbox} >
        {children}
    </tbody >
))

const  Row  =  SortableElement(({data, ...other}) => {
    return (
            <tr {...other}>
                { other.children && other.children[0]}
                <td style={{width :  '5%' }} >
                    <DragHandle/>
                </td>
                <td>
                    {data.name}
                </td>
                <td>
                    {data.codeid}
                </td>
                <td>
                    {data.created}
                </td>
                <td>
                    {data.actions}
                </td>
            </tr >

    )
})
class StockCodeChildList extends CoreEngine {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataSource: [],
            rand:0,
            pageSize:10,
            stockcode:"",
            stockcodelist:[],
            page:0,
            count:0,
        };
        this.pathname = "stockcodechild";
        this.engine = new RequestEngine();
    }

    componentDidMount() {
        this.checkPermission();
        this.prepareData();
    }




    async saveOrder() {

        const response = await  this.engine.postItem(this.pathname,"/updatelistorder/"+this.state.stockcode,this.state.dataSource);

        if(response && response.status === 200){

        }


        this.props.loadingAction(false);
    }


    async prepareData(){
        let responseuni = await this.engine.getItemlistDirect("stockcode");
        if(responseuni){
            const data = responseuni.data.data;
            let stockcodelist = []
            data.map(p =>{
                stockcodelist.push( { value: p._id, label: p.name})
                }
            )
            this.setState({
                stockcodelist,
                internalload:false
            });
        }


        this.setState({
            isLoading:false
        })
    }



    checkPermission(){
        const managertype = localStorage.getItem("managertype");
        if(!managertype || managertype=="normaluser"){
            this.props.history.push('/admin/notfound');
        }
    }





    async handleDelete(valueid) {
        this.props.loadingAction(true)
        const result = await this.engine.deleteItem(this.pathname,valueid)
        if(result){
            this.handlePage();
        }
        this.props.loadingAction(false)

    }



    preparecol(value){

        let output = [];
        value.map(x=>{
            output.push(x.name)
        })
        return output.join(",")
    }
    async callPage() {
        this.setState({
            isLoading: true,
        })
        const response = await  this.engine.getItem(this.pathname,"/list/"+this.state.stockcode);

        if(response && response.status === 200){
            this.setState({
                    isLoading: false,
                    dataSource: response.data.data.map((item, key) => {
                        return {
                            id: key,
                            data:item,
                            name: item.name ,
                            codeid:item.codeid,
                            stockcode:item.stockcode && item.stockcode.name,
                            createdAt:this.renderDate(item.createdAt),
                            actions: (
                                // we've added some custom button actions
                                this.handleActions(key,item)
                            )

                        };
                    })
                }
            );
        }


        this.props.loadingAction(false);
    }
    handleActions(index,item) {

        return (<UncontrolledDropdown>
            <DropdownToggle
                aria-expanded={false}
                aria-haspopup={true}
                caret
                className="btn-round btn-block"
                color="primary"
                data-toggle="dropdown"
                id="dropdownMenuButton"
                type="button"
            >
                Actions
            </DropdownToggle>
            <DropdownMenu>

                <DropdownItem  eventKey="2" onClick={() => this.handleEdit(index,item._id)}>Edit</DropdownItem>
                <DropdownItem variant="danger" eventKey="1" onClick={() => this.handleDelete(item._id)}>Delete</DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>)
    }
    handleEdit(index,dataid) {
        const data = this.state.dataSource[index].data

        this.props.history.push({
            pathname:'/admin/'+this.pathname+'/edit/'+dataid,
            data
        });

    }
    handlePage() {
        this.props.loadingAction(true);
        this.callPage()
    }


    handleAdd() {
        this.props.history.push('/admin/'+this.pathname+'/add/'+this.state.stockcode);
    }

    // Handler for traversing completion, helper arrayMove is used
    onSortEnd  = ({oldIndex, newIndex}) => {
        this.setState ({
            dataSource : arrayMove(this.state.dataSource , oldIndex, newIndex),
        },()=>{
            this.saveOrder();
        });
    };


    render() {
        const {dataSource,isLoading,count,page,pageSize,stockcode,stockcodelist} = this.state;

        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= count; i++) {
            pageNumbers.push(i);
        }
        const renderPageNumbers = pageNumbers.map((number, index) => {
            return (
                <PaginationItem   key={index}>
                    <PaginationLink
                        onClick={()=>this.callPage(number,pageSize)}
                    >
                        {number}
                    </PaginationLink>
                </PaginationItem>
            );
        })




        return (
            <>

                <div className="content">

                        <Col md="12">
                            <Card>

                                <CardHeader>
                                    <CardTitle tag="h4" className="pull-left">List</CardTitle>

                                    {stockcode && <Button color="primary" className="pull-right" onClick={() =>
                                        this.handleAdd()
                                    }>
                                        Add
                                    </Button>}

                                </CardHeader>


                                <Col md="6">
                                <SelectRow  label={"Stock code"} name={stockcode}  defaultValue={stockcode}  data={stockcodelist}  changeInput={(e)=>{
                                    this.setState({
                                        stockcode:e.value
                                    },()=>{
                                        this.callPage();
                                    })
                                }}  />
                                </Col>
                                <CardBody>

                                    <Table>
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Code id</th>
                                            <th>Created</th>
                                            <th>Actions</th>
                                        </tr>
                                        </thead>
                                        <TableBodySortable onSortEnd = {this.onSortEnd} useDragHandle displayRowCheckbox={false}>
                                            {dataSource.map((row,index) => {
                                                return (
                                                    <Row
                                                        index={index}
                                                        key={row.id}
                                                        data={row}
                                                    />
                                                )
                                            })}
                                        </TableBodySortable>
                                    </Table>
                                    <Pagination
                                        className="pagination pagination-primary"
                                        listClassName="pagination-primary"
                                        style={{    overflow: "scroll"}}
                                    >
                                        {renderPageNumbers}
                                    </Pagination>

                                </CardBody>
                            </Card>
                        </Col>


                </div>
            </>
        );
    }
}


const mapStateToProps = state => ({
    bids:state.bids,
});

const mapDispatchToProps = dispatch => ({
    loadingAction: (payload) => dispatch(loadingAction(payload))
});
export default connect(mapStateToProps, mapDispatchToProps)(StockCodeChildList);
